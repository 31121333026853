import { Select } from "@/shared/ui/Form";
import React from "react";
import { useTranslation } from "react-i18next";

interface IOfferTitleBlockProps {
  byPartnerFilters: {
    value: string;
    label: string;
  }[];
  currentPartnerFilter: string;
  handlePartnerFilterChange: (partnerName?: string) => void;
  handleAdd: () => void;
}

function OfferTitleBlock({
  byPartnerFilters,
  currentPartnerFilter,
  handlePartnerFilterChange,
  handleAdd,
}: IOfferTitleBlockProps) {
  const { t } = useTranslation();
  return (
    <div className="profile__title-block">
      <div style={{ display: "flex", gap: "24px", alignItems: "center" }}>
        <strong className="profile__title">{t("Offers")}</strong>
        <style>{`
        .dropdown.partners-filter {
           z-index: 4;
        }

        .profile__filter.partners-filter .btn {
          max-width: 300px;
        }
        `}</style>
        <Select
          className="profile__filter partners-filter"
          dropDownClassName="bottom bottom-end partners-filter"
          value={currentPartnerFilter}
          setValue={handlePartnerFilterChange}
          items={byPartnerFilters}
          button={(label) => (
            <button className="btn btn-outline lighten-up scale-up black">
              <i className="btn__text select__btn-text">{t(label!)}</i>
              <span className="icon icon-cursor-down select__btn-icon"></span>
            </button>
          )}
          option={(item) => (
            <em className="dropdown__text select__text">{t(item)}</em>
          )}
        />
      </div>
      <button
        className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
        onClick={handleAdd}
      >
        <span className="icon icon-plus btn__icon" />
        <i className="btn__text">{t("OffersAdd")}</i>
      </button>
    </div>
  );
}

export default OfferTitleBlock;
