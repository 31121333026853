import UserStore from "@/app/providers/MobxStore/model/UserStore";
import { useGetAuthorList } from "@op/entities";

export const useUserAuthor = () => {
  const { data: userData, isAuth } = UserStore;

  const { data: authors } = useGetAuthorList();
  const userAuthorData = authors?.find(
    (author) => author.user_id === userData?.user_id
  );

  return {
    userAuthor: {
      ...userAuthorData,
      ...userData,
    },
    isAuth,
  };
};
