import { UserStore } from "@/app/providers/MobxStore";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { notify } from "@/shared/lib/utils/notify";
import {
  EPartnerStatus,
  IPartner,
  useAddPartnerMutation,
  useEditPartnerMutation,
} from "@op/entities";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PartnerModalForm from "./PartnerModalForm";

interface IModalProps extends IModalOpen {
  data: Nullable<IPartner>;
  onSave?: () => void;
  className?: string;
}

type TFormFields = Omit<Partial<IPartner>, "offers">;

export const defaultValues: TFormFields = {
  description: "",
  index: "",
  is_big: false,
  logo_image: "",
  name: "",
  partner_status: EPartnerStatus.published,
  slogan: "",
  header: "",
  seo_description: "",
  seo_title: "",
};

const PartnerModal = ({
  data,
  onClose,
  open,
  onSave,
  className,
}: IModalProps) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [addPartner, { isLoading: isAddPartnerLoading }] =
    useAddPartnerMutation();
  const [editPartner, { isLoading: isEditPartnerLoading }] =
    useEditPartnerMutation();
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    if (isDirty) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  const handleSave = async (formData: TFormFields) => {
    const response = data
      ? await editPartner({
          ...formData,
          id: data.id,
          token: UserStore.token ?? "",
        })
      : await addPartner({
          ...formData,
          token: UserStore.token ?? "",
        });

    if ("error" in response) {
      notify(t("AnErrorOcurred"));
    } else {
      onSave?.();
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
    }
  }, [open]);

  const isLoading = isAddPartnerLoading || isEditPartnerLoading;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classNames("profile-modal", className)}
    >
      <>
        <PartnerModalForm
          data={data}
          isLoading={isLoading}
          onSubmit={handleSave}
          onDirtyChange={setIsDirty}
        />
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default PartnerModal;
