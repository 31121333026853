import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import { Input, Radio } from "@/shared/ui/Form";
import { IJournalTag } from "@op/entities";
import classNames from "classnames";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type TFormFields = Omit<IJournalTag, "tag_id">;

interface IJournalTagsFormProps {
  data?: IJournalTag;
  isLoading: boolean;
  onSubmit: (formData: TFormFields) => Promise<void>;
  onDirtyChange: (isDirty: boolean) => void;
}

export const defaultValues: TFormFields = {
  display_order: 0,
  is_enabled: true,
  tag: "",
  tagslug: "",
  is_main: false,
  seo_description: "",
  seo_title: "",
};

export const JournalTagsModalForm = ({
  data,
  isLoading,
  onSubmit,
  onDirtyChange,
}: IJournalTagsFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  useEffect(() => {
    onDirtyChange(Object.keys(dirtyFields).length > 0);
  }, [dirtyFields, onDirtyChange]);

  return (
    <form className="profile-modal__holder" onSubmit={handleSubmit(onSubmit)}>
      <div className="profile-modal__header">
        {data ? (
          <span className="profile-modal__header-title">{t("TagEdit")}</span>
        ) : (
          <>
            <span className="profile-modal__header-title">{t("TagAdd")}</span>
            <p className="profile-modal__header-text">
              {t("TagAddDescription")}
            </p>
          </>
        )}
      </div>
      <div className="form profile-modal__form">
        <div className="form__block">
          <span className="label">{t("Name")}</span>
          <Input
            {...register("tag", {
              ...requiredValidateWhiteSpaces(t("PasteName")),
            })}
            isInvalid={!!errors.tag}
            errorText={errors.tag?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("Identifier")}</span>
          <Input
            {...register("tagslug", {
              ...requiredValidateWhiteSpaces(t("PasteIdentifier")),
              pattern: {
                value: /^([a-zA-Z0-9_-]+)$/,
                message: t("OnlyLatin"),
              },
            })}
            isInvalid={!!errors.tagslug}
            errorText={errors.tagslug?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("TagSEOTitle")}</span>
          <Input
            {...register("seo_title", {
              ...requiredValidateWhiteSpaces(t("PasteTitle")),
            })}
            isInvalid={!!errors.seo_title}
            errorText={errors.seo_title?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("TagSEODescription")}</span>
          <Input
            {...register("seo_description", {
              ...requiredValidateWhiteSpaces(t("PasteSEODescription")),
            })}
            isInvalid={!!errors.seo_description}
            errorText={errors.seo_description?.message}
          />
        </div>
        <div className="form__block profile-modal__radio-block">
          <Controller
            name="is_enabled"
            control={control}
            render={({ field }) => (
              <Radio
                value={!!field.value}
                onChange={field.onChange}
                label={t("TagShowOnHome")}
              />
            )}
          />
        </div>
        <div className="form__block profile-modal__radio-block">
          <Controller
            name="is_main"
            control={control}
            render={({ field }) => (
              <Radio
                value={!!field.value}
                onChange={field.onChange}
                label={t("TagMain")}
              />
            )}
          />
        </div>

        <div className="form__block">
          <span className="label">{t("Order")}</span>
          <Input
            type="number"
            {...register("display_order")}
            isInvalid={!!errors.display_order}
            errorText={errors.display_order?.message}
          />
        </div>
        <button
          className={classNames(
            "btn btn-fond scale-up lighten-up btn-full blue",
            Object.keys(errors).length > 0 && "btn-disabled"
          )}
          type="submit"
        >
          {data ? (
            <i className="btn__text">{isLoading ? t("Saving") : t("Save")}</i>
          ) : (
            <i className="btn__text">{isLoading ? t("Saving") : t("TagAdd")}</i>
          )}
        </button>
      </div>
    </form>
  );
};
