export enum EFilterTabs {
  published,
  isBig,
  hidden,
}

export const FILTER_TABS = [
  { value: EFilterTabs.published, label: "Published" },
  { value: EFilterTabs.isBig, label: "IsBig" },
  { value: EFilterTabs.hidden, label: "Hidden" },
];
