import { UserStore } from "@/app/providers/MobxStore";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { notify } from "@/shared/lib/utils/notify";
import {
  IJournalTag,
  useAddJournalTagMutation,
  useEditJournalTagMutation,
  useGetJournalTagList,
} from "@op/entities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { JournalTagsModalForm, TFormFields } from "./JournalTagsModalForm";

interface IModalProps extends IModalOpen {
  data?: IJournalTag;
  onSave?: () => void;
}

const JournalTagsModal = ({ open, onClose, data, onSave }: IModalProps) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { data: tags } = useGetJournalTagList();
  const [addTag, { isLoading: isAdding }] = useAddJournalTagMutation();
  const [editTag, { isLoading: isEditing }] = useEditJournalTagMutation();
  const [isDirty, setIsDirty] = useState(false);

  const handleSave = async (formData: TFormFields) => {
    const lastTagId =
      tags?.reduce((maxId, tag) => (maxId = Math.max(tag.tag_id, maxId)), 0) ||
      0;

    const response = data
      ? await editTag({
          ...formData,
          tag_id: data.tag_id,
          token: UserStore.token!,
        })
      : await addTag({
          ...formData,
          tag_id: lastTagId + 1,
          token: UserStore.token!,
        });

    if ("error" in response) {
      notify(t("AnErrorOcurred"));
    } else {
      onSave?.();
      onClose();
    }
  };

  const handleClose = () => {
    if (isDirty) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
    }
  }, [open]);

  const isLoading = isAdding || isEditing;

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <JournalTagsModalForm
          data={data}
          isLoading={isLoading}
          onSubmit={handleSave}
          onDirtyChange={setIsDirty}
        />
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default JournalTagsModal;
