import { $api, rtkApi } from '@op/api';

import { API_PROMO_INTERNAL_URL, API_PROMO_URL } from '../../Env';
import {
  IFaqArticleAddRequest,
  IFaqArticleCountersGetRequest,
  IFaqArticleCountersGetResponse,
  IFaqArticleDeleteRequest,
  IFaqArticleDetailGetRequest,
  IFaqArticleDetailGetResponse,
  IFaqArticleEditRequest,
  IFaqArticleLikeRequest,
  IFaqArticleListGetRequest,
  IFaqArticleListGetResponse,
  IFaqArticleRestoreRequest,
  IFaqArticleViewRequest,
} from '../model/types/faqArticle';

const getEndpoint = (apiUrl: string | undefined) => {
  if (!apiUrl) {
    throw new Error('No API endpoint provided');
  }

  return `${apiUrl}/faq/articles`;
};

const requestListOptions = (apiUrl: string | undefined, { token, ...params }: IFaqArticleListGetRequest) => ({
  url: getEndpoint(apiUrl),
  params,
  ...(token && {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }),
});

const requestDetailOptions = (apiUrl: string | undefined, { index, token, ...params }: IFaqArticleDetailGetRequest) => ({
  url: `${getEndpoint(apiUrl)}/${index ?? ''}`,
  params,
  ...(token && {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }),
});

const requestCountersOptions = (apiUrl: string | undefined, { index }: IFaqArticleCountersGetRequest) => ({
  url: `${getEndpoint(apiUrl)}/${index ?? ''}/counters`,
});

const requestMutateOptions = (
  apiUrl: string | undefined,
  { token, ...params }: IFaqArticleEditRequest | IFaqArticleAddRequest | IFaqArticleDeleteRequest,
) => ({
  url: `${getEndpoint(apiUrl)}/${params.id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getFaqArticleList: build.query<IFaqArticleListGetResponse, IFaqArticleListGetRequest>({
      query: (params) => requestListOptions(API_PROMO_URL, params),
    }),
    getFaqArticleDetail: build.query<IFaqArticleDetailGetResponse, IFaqArticleDetailGetRequest>({
      query: (params) => requestDetailOptions(API_PROMO_URL, params),
    }),
    getFaqArticleCounters: build.query<IFaqArticleCountersGetResponse, IFaqArticleCountersGetRequest>({
      query: (params) => requestCountersOptions(API_PROMO_URL, params),
    }),
    addFaqArticle: build.mutation<number, IFaqArticleAddRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'post',
      }),
    }),
    editFaqArticle: build.mutation<number, IFaqArticleEditRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'put',
      }),
    }),
    deleteFaqArticle: build.mutation<number, IFaqArticleDeleteRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'delete',
      }),
    }),
    restoreFaqArticle: build.mutation<number, IFaqArticleRestoreRequest>({
      query: (params) => ({
        url: `${getEndpoint(API_PROMO_URL)}/restore/${params.id ?? ''}`,
        body: params,
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
        method: 'post',
      }),
    }),
    likeFaqArticle: build.mutation<void, IFaqArticleLikeRequest>({
      query: (params) => ({
        url: `${getEndpoint(API_PROMO_URL)}/like/${params.id ?? ''}`,
        method: 'post',
      }),
    }),
    viewFaqArticle: build.mutation<void, IFaqArticleViewRequest>({
      query: (params) => ({
        url: `${getEndpoint(API_PROMO_URL)}/view-counts/${params.id ?? ''}`,
        method: 'post',
      }),
    }),
  }),
});

export const {
  useGetFaqArticleListQuery: useGetFaqArticleList,
  useLazyGetFaqArticleListQuery: useLazyGetFaqArticleList,
  useGetFaqArticleDetailQuery: useGetFaqArticleDetail,
  useLazyGetFaqArticleDetailQuery: useLazyGetFaqArticleDetail,
  useGetFaqArticleCountersQuery: useGetFaqArticleCounters,
  useLazyGetFaqArticleCountersQuery: useLazyGetFaqArticleCounters,
  useAddFaqArticleMutation,
  useEditFaqArticleMutation,
  useDeleteFaqArticleMutation,
  useLikeFaqArticleMutation,
  useViewFaqArticleMutation,
  useRestoreFaqArticleMutation,
} = api;

export const getFaqArticleList = async (params: IFaqArticleListGetRequest) => {
  const faqArticleList = await $api.request<IFaqArticleListGetResponse>(requestListOptions(API_PROMO_INTERNAL_URL, params));
  return faqArticleList.data;
};

export const getFaqArticleDetail = async (params: IFaqArticleDetailGetRequest) => {
  const faqArticle = await $api.request<IFaqArticleDetailGetResponse>(requestDetailOptions(API_PROMO_INTERNAL_URL, params));
  return faqArticle.data;
};

export const getFaqArticleCounters = async (params: IFaqArticleCountersGetRequest) => {
  const faqArticleCounters = await $api.request<IFaqArticleCountersGetResponse>(requestCountersOptions(API_PROMO_INTERNAL_URL, params));
  return faqArticleCounters.data;
};
