import { useUserAuthor } from "@/shared/lib/hooks/useUserAuthor";
import { Author } from "@/shared/ui/Author";
import { observer } from "mobx-react-lite";
import React from "react";

const ProfileHeader = () => {
  const { userAuthor } = useUserAuthor();

  return (
    <div className="profile-header">
      <Author
        name={userAuthor?.name || userAuthor?.full_name}
        initials={userAuthor?.initials || userAuthor?.username?.slice(0, 2)}
        avatar={userAuthor?.avatar}
      />
    </div>
  );
};

export default observer(ProfileHeader);
