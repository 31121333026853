import { IOffer, IOfferEditRequest } from "@op/entities";

export function offerResponseToRequestTypeMapper(
  response: Nullable<IOffer>
): Nullable<IOfferEditRequest> {
  if (!response) return null;
  const {
    offer_scopes,
    partner_name,
    partner_description,
    partner_logo,
    partner_id,
    ...rest
  } = response;

  return {
    ...(rest as IOfferEditRequest),
    offer_scopes_id: offer_scopes?.map(({ id }) => id),
  };
}
