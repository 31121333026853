import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_PROMO_INTERNAL_URL, API_PROMO_URL } from '../../Env';
import {
  IPartner,
  IPartnerAddRequest,
  IPartnerChangeStatusRequest,
  IPartnerDeleteRequest,
  IPartnerDeleteResponse,
  IPartnerEditRequest,
  IPartnerMutationResponse,
  IPartnersDetailGetRequest,
  IPartnersListGetResponse,
} from '../model/types/partner';

const getEndpoint = (apiUrl: string | undefined) => {
  if (!apiUrl) {
    throw new Error('No API endpoint provided');
  }

  return `${apiUrl}/partners`;
};

const requestListOptions = (apiUrl: string | undefined) => ({
  url: getEndpoint(apiUrl),
});

const requestPartnerByIndexOptions = (apiUrl: string | undefined, index: string) => ({
  url: `${getEndpoint(apiUrl)}/${index}`,
});

const requestChangePartnerStatusOptions = (apiUrl: string | undefined, { token, id, ...params }: IPartnerChangeStatusRequest) => ({
  url: `${getEndpoint(apiUrl)}/change-status/${id ?? ''}?partnerStatus=${params.partner_status}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
  method: 'PATCH',
});

const requestMutateOptions = (
  apiUrl: string | undefined,
  { token, ...params }: IPartnerDeleteRequest | IPartnerAddRequest | IPartnerEditRequest,
) => ({
  url: `${getEndpoint(apiUrl)}/${params.id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getPartners: build.query<IPartnersListGetResponse, {}>({
      query: () => requestListOptions(API_PROMO_URL),
    }),
    getPartnerByIndex: build.query<IPartner, IPartnersDetailGetRequest>({
      query: ({ index }) => requestPartnerByIndexOptions(API_PROMO_URL, index),
    }),
    changePartnerStatus: build.mutation<IPartnerMutationResponse, IPartnerChangeStatusRequest>({
      query: (params) => requestChangePartnerStatusOptions(API_PROMO_URL, params),
    }),
    addPartner: build.mutation<IPartnerAddRequest, IPartnerAddRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'post',
      }),
    }),
    editPartner: build.mutation<IPartnerEditRequest, IPartnerEditRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'put',
      }),
    }),
    deletePartner: build.mutation<IPartnerDeleteResponse, IPartnerDeleteRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetPartnersQuery: useGetPartners,
  useGetPartnerByIndexQuery: useGetPartnerByIndex,
  useLazyGetPartnerByIndexQuery: useGetPartnerByIndexLazy,
  useChangePartnerStatusMutation,
  useDeletePartnerMutation,
  useAddPartnerMutation,
  useEditPartnerMutation,
} = api;

export const getPartners = (): Promise<AxiosResponse<IPartnersListGetResponse>> => $api.request(requestListOptions(API_PROMO_INTERNAL_URL));

export const getPartnerByIndex = (index: string): Promise<AxiosResponse<IPartner>> =>
  $api.request(requestPartnerByIndexOptions(API_PROMO_INTERNAL_URL, index));
