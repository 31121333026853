import { UserStore } from "@/app/providers/MobxStore";
import { useModalConfirm } from "@/shared/lib/hooks/useModalComfirm";
import { useModalOpen } from "@/shared/lib/hooks/useModalOpen";
import { useOffersFilters } from "@/shared/lib/hooks/useOffersFilters";
import { notify } from "@/shared/lib/utils/notify";
import {
  IOffer,
  IOfferRequest,
  useChangeOfferStatusMutation,
  useDeleteOfferMutation,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useState } from "react";

import { offerResponseToRequestTypeMapper } from "../utils";

export function useOfferActions() {
  const { offersData, refetchOffersData } = useOffersFilters();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();

  const [modalEditorData, setModalEditorData] =
    useState<Nullable<IOfferRequest>>(null);
  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [changeOfferStatus] = useChangeOfferStatusMutation();
  const [deleteOffer] = useDeleteOfferMutation();

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleEdit = (id: IOffer["id"]) => {
    const itemToEdit = offersData?.find((offer) => offer.id === id);
    if (itemToEdit) {
      setModalEditorData(offerResponseToRequestTypeMapper(itemToEdit));
      setModalEditorOpened(true);
    }
  };

  const handleAdd = () => {
    setModalEditorData(null);
    setModalEditorOpened(true);
  };

  const handleChangeOfferStatus = async (id: number, status: boolean) => {
    const response = await changeOfferStatus({
      id,
      token: UserStore.token ?? "",
      is_enabled: status,
    });
    refetchOffersData();
    if ("error" in response) {
      console.error(response.error);
      notify((response.error as FetchBaseQueryError).data);
      return;
    }
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await deleteOffer({
        id: modalConfirm.id,
        token: UserStore.token ?? "",
      });
      if ("error" in response) {
        console.error(response.error);
        notify((response.error as FetchBaseQueryError).data);
        return;
      }

      if ("data" in response) {
        setModalConfirm({ isOpen: false, id: null });
        refetchOffersData();
      }
    }
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    refetchOffersData();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  return {
    handleAdd,
    handleConfirm,
    handleEdit,
    handleChangeOfferStatus,
    modalEditorOpened,
    setModalEditorOpened,
    modalEditorData,
    handleModalEditorOnSave,
    modalConfirm,
    handleDelete,
  };
}
