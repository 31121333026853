import { IAuthorizedRequest } from '../../../shared/types/entities';

/* eslint-disable camelcase */
export interface IOfferScopeResponse {
  // список тэгов для фильтрации предложений
  id: number;
  index: string;
  name: string;
}

// eslint-disable-next-line no-shadow
export enum EOfferType { // типы предложений
  promocode = 'promocode',
  discount = 'discount',
  trial = 'trial',
}

export interface IOffer {
  id: number;
  partner_site_url: string;
  partner_id: number;
  partner_name: string;
  partner_description: string;
  partner_logo: string;
  partner_index: string; // index партнера для перехода на его детальную страницу
  partner_is_big: boolean; // большой ли партнер (есть ли у него детальная страница или только модалка у предложения)
  full_title: string; // отображается на детальной странице партнера или в модалке предложения в промо
  short_title: string; // отображается в списке предложений в промо
  is_enabled: boolean; // активное или завершенное предложение
  type: EOfferType;
  highlight_offer: string; // текст со специальным предложением (не обязательное поле)
  amount: number; // размер скидки пока нигде не используется
  description: string;
  promo_code: string | null; // текст для кнопки с промокодом (не обязательное поле)
  offer_scopes: IOfferScopeResponse[]; // список тэгов для фильтрации предложений
  order: number; // порядковый номер в списке предложений для отображения по порядку
}

export interface IOfferRequest extends Partial<IOffer> {
  offer_scopes_id?: number[]; // для редактирования тегов в админке нужны только id
}

export interface IOffersListGetRequest extends Partial<Pick<IOffer, 'partner_index' | 'is_enabled'>> {}

export interface IOfferChangeStatusRequest extends IAuthorizedRequest, Pick<IOffer, 'id' | 'is_enabled'> {}

export interface IOfferAddRequest
  extends IAuthorizedRequest,
    Omit<IOfferRequest, 'partner_name' | 'partner_description' | 'partner_logo' | 'partner_id' | 'partner_is_big' | 'offer_scopes'> {}

export interface IOfferEditRequest
  extends IAuthorizedRequest,
    Omit<IOfferRequest, 'partner_name' | 'partner_description' | 'partner_logo' | 'offer_scopes'> {}

export interface IOfferDeleteRequest extends IAuthorizedRequest, Pick<IOffer, 'id'> {}

export type IOfferChangeStatusResponse = boolean;
