import { UserStore } from "@/app/providers/MobxStore";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { notify } from "@/shared/lib/utils/notify";
import {
  IOfferRequest,
  useAddOfferMutation,
  useEditOfferMutation,
} from "@op/entities";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TOfferModalFormFields } from "../model/types/offerModalFrom";
import OfferModalForm from "./OfferModalForm";

interface IModalProps extends IModalOpen {
  data: Nullable<IOfferRequest>;
  onSave?: () => void;
  partnersList: {
    value: string;
    label: string;
  }[];
  className?: string;
}

const OfferModal = ({
  data,
  onClose,
  open,
  onSave,
  partnersList,
  className,
}: IModalProps) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [addOffer, { isLoading: isAddOfferLoading }] = useAddOfferMutation();
  const [editOffer, { isLoading: isEditOfferLoading }] = useEditOfferMutation();
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    if (isDirty) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  const handleSave = async (formData: TOfferModalFormFields) => {
    const response = data
      ? await editOffer({
          ...formData,
          id: data.id,
          token: UserStore.token ?? "",
        })
      : await addOffer({
          ...formData,
          token: UserStore.token ?? "",
        });

    if ("error" in response) {
      notify(t("AnErrorOcurred"));
    } else {
      onSave?.();
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
    }
  }, [open]);

  const isLoading = isAddOfferLoading || isEditOfferLoading;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classNames("profile-modal", className)}
    >
      <>
        <OfferModalForm
          data={data}
          isLoading={isLoading}
          onSubmit={handleSave}
          partnersList={partnersList}
          onDirtyChange={setIsDirty}
        />
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default OfferModal;
