import { UserStore } from "@/app/providers/MobxStore";
import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import { ConfirmModal } from "@/features/Modal";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { useModalConfirm } from "@/shared/lib/hooks/useModalComfirm";
import { useModalOpen } from "@/shared/lib/hooks/useModalOpen";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import { Input, Radio } from "@/shared/ui/Form";
import { PartnerModal } from "@/widgets/PartnerModal";
import {
  EPartnerStatus,
  IPartner,
  IPartnerChangeStatusRequest,
  IPartnerDeleteRequest,
  useChangePartnerStatusMutation,
  useDeletePartnerMutation,
  useGetOffers,
  useGetPartners,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getOfferKey } from "../lib/utils";
import { EFilterTabs, FILTER_TABS } from "../model/const/partnersConsts";

function PartnersList() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] =
    useState<Nullable<IPartner>>(null);
  const [currentFilterTab, setCurrentFilterTab] = useState(
    EFilterTabs.published
  );
  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);
  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const {
    data: partnersData,
    isFetching: partnersDataIsFetching,
    refetch: refetchpartnersData,
  } = useGetPartners({});

  const { refetch: refetchOffersData } = useGetOffers({});
  const [changePartnerStatus] = useChangePartnerStatusMutation();
  const [deletePartner] = useDeletePartnerMutation();

  const partnersPublished = useMemo(() => {
    return partnersData?.filter(
      (item) => item.partner_status === EPartnerStatus.published
    );
  }, [partnersData]);

  const partnersIsBig = useMemo(() => {
    return partnersData?.filter((item) => item.is_big);
  }, [partnersData]);

  const partnersHidden = useMemo(() => {
    return partnersData?.filter(
      (item) => item.partner_status === EPartnerStatus.publishRemoved
    );
  }, [partnersData]);

  const handleFilterSelect = (value: number) => {
    setCurrentFilterTab(value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        filteredList?.length ?? 0,
        !!next
      )
    );
  };

  const filteredList = useMemo(() => {
    const list =
      currentFilterTab === EFilterTabs.isBig
        ? partnersIsBig
        : currentFilterTab === EFilterTabs.hidden
        ? partnersHidden
        : partnersPublished ?? [];

    return (
      list?.filter((partner) =>
        partner.name.toLowerCase().includes(searchString)
      ) ?? []
    );
  }, [
    currentFilterTab,
    partnersHidden,
    partnersIsBig,
    partnersPublished,
    searchString,
  ]);

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleEdit = (id: IPartner["id"]) => {
    const itemToEdit = partnersData?.find((partner) => partner.id === id);
    if (itemToEdit) {
      setModalEditorData(itemToEdit);
      setModalEditorOpened(true);
    }
  };

  const handleAdd = () => {
    setModalEditorData(null);
    setModalEditorOpened(true);
  };

  const handleChangePartnerStatus = async (
    id: number,
    status: EPartnerStatus
  ) => {
    const response = await changePartnerStatus({
      id,
      token: UserStore.token ?? "",
      partner_status:
        status === EPartnerStatus.published
          ? EPartnerStatus.publishRemoved
          : EPartnerStatus.published,
    } as IPartnerChangeStatusRequest);
    refetchpartnersData();
    refetchOffersData();
    if ("error" in response) {
      console.error(response.error);
      notify((response.error as FetchBaseQueryError).data);
      return;
    }
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await deletePartner({
        id: modalConfirm.id,
        token: UserStore.token ?? "",
      } as IPartnerDeleteRequest);
      if ("error" in response) {
        console.error(response.error);
        notify((response.error as FetchBaseQueryError).data);
        return;
      }

      if ("data" in response) {
        setModalConfirm({ isOpen: false, id: null });
        refetchpartnersData();
        refetchOffersData();
      }
    }
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    refetchpartnersData();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("PartnersList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("PartnersAdd")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <ul className="profile__tabs">
            {FILTER_TABS.map((tab) => (
              <li
                className={classNames(
                  "profile__tab",
                  currentFilterTab === tab.value && "is-active"
                )}
                key={tab.label}
                onClick={() => handleFilterSelect(tab.value)}
              >
                <div className="profile__tab-holder">
                  <strong className="profile__tab-name">{t(tab.label)}</strong>
                  <span className="profile__tab-number">
                    {tab.value === EFilterTabs.isBig
                      ? partnersIsBig?.length ?? 0
                      : tab.value === EFilterTabs.hidden
                      ? partnersHidden?.length ?? 0
                      : partnersPublished?.length ?? 0}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="profile__control-item">
          <style>{`
            .profile__control-item:last-of-type {
                flex: 0 1 50%;
            }
          `}</style>
          <div className="search-input profile__search">
            <Input
              placeholder={t("PartnersSearch")}
              className="input_stroke profile__search-input"
              value={searchString}
              onChange={handleSearchChange}
            />
          </div>

          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={filteredList?.length || 0}
            />
          </div>
        </div>
      </div>
      <div className="profile__body">
        <style>{`
            .card-partner {
              --card-cell-font-size: 18px;
              --card-cell-font-weight: 400;
              --cell-1-width: 40%;
              --cell-2-width: 40%;
              --cell-3-width: 40%;
              --cell-4-width: 25%;
                        
              & .cell-1 {
                display: flex;
                align-items: center;
                gap: 8px;
                        
                & img {
                  width: 40px;
                  height: 40px;
                  border-radius: #{$border-radius-card-small};
                  object-fit: cover;
                }
                        
                & .cell__title {
                  & span {
                    display: block;
                        
                    &.cell__subtitle {
                      font-size: 14px;
                      letter-spacing: -2%;
                      color: #{$grey-text};
                    }
                  }
                }
              }
              .cell-3 {
                & .cell__radio {
                  margin: 0;
                }
              }
            }
          `}</style>
        {partnersDataIsFetching && filteredList.length === 0 && (
          <div>{t("Loading")}...</div>
        )}
        {filteredList
          .slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((partner) => (
            <ItemCard
              key={partner.id}
              className="card-partner"
              cardId={partner.id}
              cardLink={partner.id}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(partner.id)}
              cells={[
                <React.Fragment key={1}>
                  <img
                    src={partner.logo_image}
                    alt={partner.name}
                    width={40}
                    height={40}
                  />
                  <div className="cell__title">
                    <span>
                      {partner.name.slice(0, 25) +
                        (partner.name.length > 25 ? "..." : "")}
                    </span>
                    {partner.is_big && (
                      <span className="cell__subtitle">
                        {t("PartnerIsBig")}
                      </span>
                    )}
                  </div>
                </React.Fragment>,
                <React.Fragment key={2}>
                  {`${partner.offers.length} ${t(
                    getOfferKey(partner.offers.length)
                  )}`}
                </React.Fragment>,
                <React.Fragment key={3}>
                  <div className="form__block profile-modal__radio-block cell__radio">
                    <Radio
                      value={
                        partner.partner_status === EPartnerStatus.published
                      }
                      onChange={() =>
                        handleChangePartnerStatus(
                          partner.id,
                          partner.partner_status
                        )
                      }
                      label={
                        partner.partner_status === EPartnerStatus.published
                          ? t("PartnerPublished")
                          : t("PartnerHidden")
                      }
                    />
                  </div>
                </React.Fragment>,
              ]}
            />
          ))}
        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={filteredList?.length ?? 0}
          />
        </div>
      </div>
      <style>{`
      .profile-partner-modal {
      font-size: 16px;
        }
      `}</style>

      <PartnerModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
        className="profile-partner-modal"
      />
      <ConfirmModal
        text={t("PartnerDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(PartnersList);
