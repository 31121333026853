import { Input, MultiSelect, Radio, Select } from "@/shared/ui/Form";
import {
  EOfferType,
  EPartnerStatus,
  IOfferRequest,
  useGetOffersScopes,
  useGetPartnerByIndexLazy,
} from "@op/entities";
import { ChangeEvent, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  OFFER_TYPE_SELECT_LIST,
  defaultOfferModalFormValues,
} from "../model/consts";
import {
  ISelectItem,
  TOfferModalFormFields,
} from "../model/types/offerModalFrom";

interface IOfferModalFormProps {
  data: Nullable<IOfferRequest>;
  isLoading: boolean;
  partnersList: ISelectItem[];
  onSubmit: (formData: TOfferModalFormFields) => Promise<void>;
  onDirtyChange: (isDirty: boolean) => void;
}

const OfferFormModal = ({
  data,
  isLoading,
  onDirtyChange,
  onSubmit,
  partnersList,
}: IOfferModalFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues: data || defaultOfferModalFormValues,
    mode: "onSubmit",
  });

  const { data: allOfferScopesData } = useGetOffersScopes({});
  const [getPartner, { data: partnerData }] = useGetPartnerByIndexLazy();

  useEffect(() => {
    if (!data) return;

    getPartner({ index: data.partner_index || "" });
  }, [data, getPartner]);

  useEffect(() => {
    reset(data || defaultOfferModalFormValues);
  }, [data, reset]);

  const offerScopesOptions = useMemo(() => {
    return allOfferScopesData?.map((scope) => ({
      value: scope.id,
      label: scope.name,
    }));
  }, [allOfferScopesData]);

  useEffect(() => {
    onDirtyChange(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields, onDirtyChange, Object.keys(dirtyFields).length]);

  const handleChangePartnerSelect = (value: string | undefined) => {
    setValue("is_enabled", false);
    getPartner({ index: value || "" });
    value && setValue("partner_index", value);
  };

  const handleChangeTypeSelect = (value: string | undefined) => {
    value && setValue("type", value as EOfferType);
  };

  const handleURLChange = (e: ChangeEvent<HTMLInputElement>) => {
    let url = e.target.value.trim();
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      url = `https://${url}`;
    }
    setValue("partner_site_url", url, { shouldDirty: true });
  };

  return (
    <form className="profile-modal__holder" onSubmit={handleSubmit(onSubmit)}>
      <div className="profile-modal__header">
        {data ? (
          <span className="profile-modal__header-title">{t("OffersEdit")}</span>
        ) : (
          <span className="profile-modal__header-title">{t("OffersAdd")}</span>
        )}
      </div>
      <div className="form profile-modal__form">
        <div className="form__block">
          <Controller
            name={"partner_index"}
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { value } }) => (
              <>
                <style>{`
                .dropdown.offer-modal-select {
                  max-width: 450px;
                  white-space: wrap;
                }

                .profile__filter.offer-modal-select .btn {
                    max-width: 450px;
                }     
                    
                  .select.is-invalid {
                     & .btn {
                       border: 2px solid rgba(255, 46, 15, 1);
                     }
                   }
                `}</style>

                <Select
                  className="profile__filter offer-modal-select"
                  dropDownClassName="bottom bottom-start offer-modal-select"
                  value={value}
                  isInvalid={!!errors.partner_index}
                  setValue={handleChangePartnerSelect}
                  items={partnersList}
                  button={(label) => (
                    <button
                      type="button"
                      className="btn btn-outline lighten-up scale-up black"
                    >
                      <i className="btn__text select__btn-text">
                        {label ? t(label!) : t("OfferPartner")}
                      </i>
                      <span className="icon icon-cursor-down select__btn-icon"></span>
                    </button>
                  )}
                  option={(item) => (
                    <em className="dropdown__text select__text">{t(item)}</em>
                  )}
                />
              </>
            )}
          />
        </div>
        <div className="form__block">
          <Controller
            name={"offer_scopes_id"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <MultiSelect
                  className="profile__filter offer-modal-select"
                  dropDownClassName="bottom bottom-start offer-modal-select"
                  isInvalid={!!errors.offer_scopes_id}
                  value={value}
                  setValue={(newValue, e?: React.SyntheticEvent) => {
                    e?.preventDefault();
                    onChange(newValue);
                  }}
                  items={offerScopesOptions}
                  button={(label) => (
                    <button
                      type="button"
                      className="btn btn-outline lighten-up scale-up black"
                    >
                      <i className="btn__text select__btn-text">
                        {label ? t(label!) : t("OfferScopes")}
                      </i>
                      <span className="icon icon-cursor-down select__btn-icon"></span>
                    </button>
                  )}
                  option={(item) => (
                    <em className="dropdown__text select__text">{t(item)}</em>
                  )}
                />
              );
            }}
          />
        </div>
        <div className="form__block">
          <Controller
            name={"type"}
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { value } }) => (
              <>
                <Select
                  className="profile__filter offer-modal-select"
                  dropDownClassName="bottom bottom-start offer-modal-select"
                  value={value}
                  isInvalid={!!errors.type}
                  setValue={handleChangeTypeSelect}
                  items={OFFER_TYPE_SELECT_LIST}
                  button={(label) => (
                    <button
                      type="button"
                      className="btn btn-outline lighten-up scale-up black"
                    >
                      <i className="btn__text select__btn-text">
                        {label ? t(label!) : t("OfferType")}
                      </i>
                      <span className="icon icon-cursor-down select__btn-icon"></span>
                    </button>
                  )}
                  option={(item) => (
                    <em className="dropdown__text select__text">{t(item)}</em>
                  )}
                />
              </>
            )}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferFullTitle")}</span>
          <Input
            {...register("full_title", {
              required: true,
              maxLength: {
                value: 73,
                message: t("TextMaxLength", { maxLength: 73 }),
              },
            })}
            isInvalid={!!errors.full_title}
            errorText={errors.full_title?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferShortTitle")}</span>
          <Input
            {...register("short_title", {
              required: true,
              maxLength: {
                value: 70,
                message: t("TextMaxLength", { maxLength: 70 }),
              },
            })}
            isInvalid={!!errors.short_title}
            errorText={errors.short_title?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferHighlight")}</span>
          <Input
            {...register("highlight_offer", {
              maxLength: {
                value: 30,
                message: t("TextMaxLength", { maxLength: 30 }),
              },
            })}
            isInvalid={!!errors.highlight_offer}
            errorText={errors.highlight_offer?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferDescription")}</span>
          <Input
            {...register("description", {
              required: true,
              maxLength: {
                value: 300,
                message: t("TextMaxLength", { maxLength: 300 }),
              },
            })}
            isInvalid={!!errors.description}
            errorText={errors.description?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferAmount")}</span>
          <Input
            {...register("amount", { required: true })}
            type="number"
            isInvalid={!!errors.amount}
            errorText={errors.amount?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferPromocode")}</span>
          <Input
            {...register("promo_code", {
              maxLength: {
                value: 16,
                message: t("TextMaxLength", { maxLength: 16 }),
              },
            })}
            isInvalid={!!errors.promo_code}
            errorText={errors.promo_code?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferPartnerURL")}</span>
          <Input
            {...register("partner_site_url", { required: true })}
            isInvalid={!!errors.partner_site_url}
            errorText={errors.partner_site_url?.message}
            onBlur={handleURLChange}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferStatus")}</span>
          <Controller
            name={"is_enabled"}
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <Radio
                  value={!!value}
                  onChange={onChange}
                  disabled={
                    partnerData?.partner_status ===
                    EPartnerStatus.publishRemoved
                  }
                  label={!!value ? t("OfferActive") : t("OfferComplete")}
                />
                {partnerData?.partner_status ===
                  EPartnerStatus.publishRemoved && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {t("PartnerHidden")}
                  </p>
                )}
              </>
            )}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("OfferOrder")}</span>
          <Input
            {...register("order", { required: true })}
            type="number"
            isInvalid={!!errors.order}
            errorText={errors.order?.message}
          />
        </div>

        <button className="btn btn-fond scale-up lighten-up btn-full blue">
          {data ? (
            <i className="btn__text">{isLoading ? t("Saving") : t("Save")}</i>
          ) : (
            <i className="btn__text">
              {isLoading ? t("Saving") : t("OffersAdd")}
            </i>
          )}
        </button>
      </div>
    </form>
  );
};

export default OfferFormModal;
