import userStore from "@/app/providers/MobxStore/model/UserStore";
import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import { ConfirmModal } from "@/features/Modal";
import { getExpertCreatorOpen } from "@/features/UI";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { useModalConfirm } from "@/shared/lib/hooks/useModalComfirm";
import { useModalOpen } from "@/shared/lib/hooks/useModalOpen";
import { formatCategoryLabel } from "@/shared/lib/utils/formatCategoryLabel";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import Input from "@/shared/ui/Form/ui/Input";
import { ExpertsModal } from "@/widgets/ExpertsModal";
import {
  IExpert,
  useDeleteExpertMutation,
  useGetExpertList,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Experts() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] = useState<IExpert>();
  const [modalConfirm, setModalConfirm] = useModalConfirm();
  const expertCreatorOpen = useSelector(getExpertCreatorOpen);
  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const {
    data: expertsList,
    isFetching: expertsListIsFetching,
    refetch: refetchExpertsList,
    error: getExpertsListError,
  } = useGetExpertList({});

  const [deleteExpert] = useDeleteExpertMutation();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        expertsList?.length ?? 0,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await deleteExpert({
        id: modalConfirm.id,
        token: userStore.token ?? "",
      });
      if ("error" in response) {
        console.error(response.error);
        notify((response.error as FetchBaseQueryError).data);
        return;
      }

      if ("data" in response) {
        setModalConfirm({ isOpen: false, id: null });
        refetchExpertsList();
      }
    }
  };

  const handleEdit = (data: IExpert) => {
    if (data) {
      setModalEditorOpened(true);
      setModalEditorData(data);
    }
  };

  const handleAdd = () => {
    setModalEditorOpened(true);
    setModalEditorData(undefined);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await refetchExpertsList();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  useEffect(() => {
    if (!expertCreatorOpen) {
      refetchExpertsList();
    }
  }, [expertCreatorOpen, refetchExpertsList]);

  useEffect(() => {
    if (getExpertsListError) {
      notify(t("AnErrorOcurredLoadingData"), "getExpertsListError");
    }
  }, [getExpertsListError, t]);

  const filteredItems = expertsList
    ?.filter((item) => item.name.toLowerCase().includes(searchString))
    .sort((a, b) => a.name.localeCompare(b.name, "ru"));

  if (expertsListIsFetching) {
    return (
      <>
        <div className="profile__title-block">
          <strong className="profile__title">{t("ExpertsList")}</strong>
        </div>
        <div className="profile__body profile-rubrics">
          <div>{t("Loading")}...</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("ExpertsList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("ExpertsAdd")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchByExperts")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={expertsList?.length ?? 0}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-rubrics">
        {filteredItems
          ?.slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((item) => (
            <ItemCard
              key={item.id}
              className="card-expert"
              cardId={item.id}
              cardLink={item.id}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(item)}
              cells={[
                <React.Fragment key={1}>{item.order}</React.Fragment>,
                <React.Fragment key={2}>
                  <strong>{item.name}</strong>
                  <p>{item.position}</p>
                </React.Fragment>,
                <React.Fragment key={3}>
                  <p>
                    {item.categories
                      .map((category) => t(formatCategoryLabel(category)))
                      .join(", ")}
                  </p>
                </React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={expertsList?.length ?? 0}
          />
        </div>
      </div>

      <ExpertsModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("ExpertsDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(Experts);
