'use client';

import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

import { buttons, colors } from '../../consts';
import buttonsScss from '../../consts/theme/buttons/buttons.module.scss';
import { OpTheme } from '../../providers';
import { TButtonColors } from '../../types';
import { pxToVwWithBreakpoints } from '../../utils';
import './buttonTypes';
import { buttonVarsMedias } from './buttonVarsMedias';

const { buttonColors } = OpTheme.vars.palette.opColors;

export const ButtonTheme = extendTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiButton-root:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        variant: 'fond',
        size: 'M',
        color: buttons.defaultButtonColor as TButtonColors,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          display: 'inline-flex',
          alignItems: 'center',
          minWidth: 1,
          textAlign: 'center',
          borderStyle: 'solid',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          fontFamily: OpTheme.typography.fontFamily,
          fontWeight: 400,
          letterSpacing: 0,
          textTransform: 'none',
          transformOrigin: '50% 50% 0',
          transform: 'scale(1)',
          ...pxToVwWithBreakpoints({
            borderWidth: 1,
          }),
          ...(ownerState.size && {
            lineHeight: `var(--OP-${[ownerState.size]}-buttons-lineHeight)`,
            height: `var(--OP-${[ownerState.size]}-buttons-minHeight)`,
            padding: `var(--OP-${[ownerState.size]}-buttons-padding)`,
            fontSize: `var(--OP-${[ownerState.size]}-buttons-fontSize)`,
            borderRadius: `var(--OP-${[ownerState.size]}-buttons-borderRadius)`,
          }),
          ...(ownerState.size &&
            ownerState.variant !== 'icon' &&
            ownerState.variant !== 'iconGhost' && {
              [`&.MuiButton-size${ownerState.size} .MuiSvgIcon-root`]: {
                width: `var(--OP-${[ownerState.size]}-buttons-iconSize)`,
                height: `var(--OP-${[ownerState.size]}-buttons-iconSize)`,
              },
              [`&.MuiButton-size${ownerState.size} .MuiButton-startIcon`]: {
                marginRight: `var(--OP-${[ownerState.size]}-buttons-iconMargin)`,
                marginLeft: 0,
              },
              [`&.MuiButton-size${ownerState.size} .MuiButton-endIcon`]: {
                marginLeft: `var(--OP-${[ownerState.size]}-buttons-iconMargin)`,
                marginRight: 0,
              },
            }),
          ...(ownerState.size &&
            ownerState.variant &&
            ownerState.variant === 'icon' && {
              width: `var(--OP-${[ownerState.size]}-buttons-minHeight)`,
              [`&.MuiButton-size${ownerState.size} .MuiSvgIcon-root`]: {
                width: 'var(--OP-svgIcon-size-width)',
                height: 'var(--OP-svgIcon-size-height)',
              },
            }),
          ...(ownerState.variant &&
            ownerState.variant === 'chips' && {
              ...pxToVwWithBreakpoints({
                borderRadius: 100,
              }),
            }),
          ...(ownerState.color &&
            ownerState.variant !== 'iconGhost' &&
            ownerState.variant !== 'chips' && {
              borderColor: buttonColors[ownerState.color].default,
              color: buttonColors[ownerState.color].default,
              '&.MuiButton-root:hover': {
                borderColor: buttonColors[ownerState.color].default,
                color: buttonColors[ownerState.color].default,
              },
            }),
          ...((ownerState.color === 'white' || ownerState.color === 'black') && {
            color: OpTheme.vars.palette.color.default,
            [`& .MuiSvgIcon-root`]: {
              color: OpTheme.vars.palette.color.default,
            },
            '&.MuiButton-root:hover': {
              color: OpTheme.vars.palette.color.default,
            },
          }),
          ...((ownerState.variant === 'fond' || ownerState.variant === 'icon') &&
            ownerState.color && {
              backgroundColor: buttonColors[ownerState.color].default,
              color: colors.white.t_100,
              [`& .MuiSvgIcon-root`]: {
                color: colors.white.t_100,
              },
              '&.MuiButton-root:hover': {
                backgroundColor: buttonColors[ownerState.color].default,
                color: colors.white.t_100,
              },
            }),
          ...((ownerState.variant === 'fond' || ownerState.variant === 'icon') &&
            (ownerState.color === 'white' || ownerState.color === 'black') && {
              color: OpTheme.vars.palette.background.default,
              [`& .MuiSvgIcon-root`]: {
                color: OpTheme.vars.palette.background.default,
              },
              '&.MuiButton-root:hover': {
                backgroundColor: buttonColors[ownerState.color].default,
                color: OpTheme.vars.palette.background.default,
              },
            }),
          ...(ownerState.variant &&
            ownerState.variant === 'chips' && {
              backgroundColor: OpTheme.vars.palette.opColors.components.chips.default,
              borderColor: OpTheme.vars.palette.opColors.components.chips.default,
              color: OpTheme.vars.palette.color.default,
              '&.MuiButton-root:hover': {
                backgroundColor: OpTheme.vars.palette.opColors.components.chips.lightenUp,
                borderColor: OpTheme.vars.palette.opColors.components.chips.lightenUp,
              },
              '&.is-active, &.is-active: hover': {
                backgroundColor: OpTheme.vars.palette.color.default,
                color: OpTheme.vars.palette.background.default,
              },
            }),
          ...(ownerState.variant === 'fond' &&
            ownerState.color === 'green' && {
              color: colors.black.t_100,
              [`& .MuiSvgIcon-root`]: {
                color: colors.black.t_100,
              },
              '&.MuiButton-root:hover': {
                backgroundColor: buttonColors.green.default,
                color: colors.black.t_100,
              },
            }),
          ...(ownerState.variant === 'shadow' && {
            backgroundColor: buttonColors.white.default,
            borderColor: buttonColors.white.default,
            boxShadow: buttons.shadow,
          }),
          ...(ownerState.variant === 'ghost' && {
            backgroundColor: 'none',
            border: 'none',
            color: OpTheme.vars.palette.color.default,
            '&.MuiButton-root:hover': {
              color: OpTheme.vars.palette.color.default,
            },
          }),
          ...(ownerState.variant === 'dark' && {
            backgroundColor: '#2F3132',
            border: 'none',
            color: OpTheme.vars.palette.color.default,
            '&.MuiButton-root:hover': {
              backgroundColor: '#2F3132',
              color: OpTheme.vars.palette.color.default,
            },
          }),
          ...(ownerState.activity && {
            transition: buttons.transition,
          }),
          ...(((ownerState.activity && ownerState.activity.includes('scaleUp')) ||
            (ownerState.activity && ownerState.activity.includes('scaleDown'))) && {
            '&:hover': {
              transform: `scale(${buttons[ownerState.activity.includes('scaleUp') ? 'scaleUp' : 'scaleDown'].hover})`,
            },
            '&:active': {
              transform: `scale(${buttons[ownerState.activity.includes('scaleUp') ? 'scaleUp' : 'scaleDown'].active})`,
            },
          }),
          ...(ownerState.variant !== 'fond' &&
            ownerState.variant !== 'icon' &&
            ownerState.activity &&
            ownerState.color &&
            ownerState.activity.includes('lightenUp') && {
              '&.MuiButton-root:hover': {
                color: buttonColors[ownerState.color].lightenUp,
                borderColor: buttonColors[ownerState.color].lightenUp,
              },
            }),
          ...((ownerState.variant === 'fond' || ownerState.variant === 'icon') &&
            ownerState.activity &&
            ownerState.color &&
            ownerState.activity.includes('lightenUp') && {
              '&.MuiButton-root:hover': {
                backgroundColor: buttonColors[ownerState.color].lightenUp,
                borderColor: buttonColors[ownerState.color].lightenUp,
              },
            }),
          ...(ownerState.variant !== 'fond' &&
            ownerState.activity &&
            ownerState.color &&
            ownerState.activity.includes('lightenDown') && {
              '&.MuiButton-root:hover': {
                color: buttonColors[ownerState.color].lightenDown,
                borderColor: buttonColors[ownerState.color].lightenDown,
              },
            }),
          ...(ownerState.variant === 'fond' &&
            ownerState.activity &&
            ownerState.color &&
            ownerState.activity.includes('lightenDown') && {
              '&.MuiButton-root:hover': {
                backgroundColor: buttonColors[ownerState.color].lightenDown,
                borderColor: buttonColors[ownerState.color].lightenDown,
              },
            }),
          ...((ownerState.variant === 'icon' || ownerState.variant === 'iconGhost') && {
            padding: 0,
            '.MuiButton-endIcon, & MuiButton-startIcon': {
              margin: 0,
            },
          }),
          ...(ownerState.variant === 'iconGhost' && {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            },
          }),
          '& .MuiButton-endIcon, & .MuiButton-startIcon': {
            overflow: 'hidden',
          },
          '&.MuiButton-root:hover svg[data-testid="IconArrowAngle"]': {
            animation: buttonsScss.animationArrowAngle,
          },
          '@keyframes arrowAngleHover': {
            '48%': {
              transform: 'translate(100%, -100%)',
            },
            '49%': {
              transform: 'translate(-100%, 100%)',
              animationDuration: '0s',
            },
            '50%': {
              transform: 'translate(-100%, 100%)',
            },
            '100%': {
              transform: 'translate(0, 0)',
            },
          },
          '&.MuiButton-root:hover svg[data-testid="IconArrow"]': {
            animation: 'arrowHover 0.6s cubic-bezier(0.58, -0.3, 0.64, 1.18) forwards',
          },
          '@keyframes arrowHover': {
            '48%': {
              transform: 'translate(100%, 0)',
            },
            '49%': {
              transform: 'translate(-100%, 0)',
              animationDuration: '0s',
            },
            '50%': {
              transform: 'translate(-100%, 0)',
            },
            '100%': {
              transform: 'translate(0, 0)',
            },
          },
          '&.MuiButton-root:hover svg[data-testid="IconBackArrow"]': {
            animation: 'arrowBackHover 0.6s cubic-bezier(0.58, -0.3, 0.64, 1.18) forwards',
          },
          '@keyframes arrowBackHover': {
            '48%': {
              transform: 'translate(-100%, 0)',
            },
            '49%': {
              transform: 'translate(100%, 0)',
              animationDuration: '0s',
            },
            '50%': {
              transform: 'translate(100%, 0)',
            },
            '100%': {
              transform: 'translate(0, 0)',
            },
          },
          ...buttonVarsMedias(),
        }),
      },
    },
  },
});
