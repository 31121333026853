import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_PROMO_URL } from '../../Env';
import {
  IExpert,
  IExpertAddRequest,
  IExpertDeleteRequest,
  IExpertEditRequest,
  IExpertGetRequest,
  IExpertListGetRequest,
  IExpertListGetResponse,
} from '../model/types/expert';

const requestListOptions = (params: IExpertListGetRequest) => ({
  url: `${API_PROMO_URL}/experts`,
});

const requestOptions = (params: IExpertGetRequest) => ({
  url: `${API_PROMO_URL}/experts/${params.id ?? ''}`,
});

const mutationRequestOptions = ({ token, ...params }: IExpertAddRequest | IExpertEditRequest | IExpertDeleteRequest) => ({
  url: `${API_PROMO_URL}/experts/${params.id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getExpert: build.query<IExpertListGetResponse, IExpertListGetRequest>({
      query: requestListOptions,
    }),
    addExpert: build.mutation<number, IExpertAddRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params),
        method: 'post',
      }),
    }),
    editExpert: build.mutation<number, IExpertEditRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params),
        method: 'put',
      }),
    }),
    deleteExpert: build.mutation<number, IExpertDeleteRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetExpertQuery: useGetExpertList,
  useLazyGetExpertQuery: useLazyGetExpertList,
  useAddExpertMutation,
  useEditExpertMutation,
  useDeleteExpertMutation,
} = api;

export const getExpertList = (params: IExpertListGetRequest): Promise<AxiosResponse<IExpertListGetResponse>> =>
  $api.request(requestListOptions(params));

export const getExpert = (params: IExpertGetRequest): Promise<AxiosResponse<IExpert>> => $api.request(requestOptions(params));

export const addExpert = (params: IExpertAddRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = mutationRequestOptions(params);
  return $api.request({ url, headers, method: 'post', data: body });
};

export const editExpert = (params: IExpertEditRequest): Promise<AxiosResponse<number>> => {
  const { url, headers, body } = mutationRequestOptions(params);
  return $api.request({ url, headers, method: 'put', data: body });
};

export const deleteExpert = (params: IExpertDeleteRequest): Promise<AxiosResponse<number>> => {
  const { url, headers } = mutationRequestOptions(params);
  return $api.request({ url, headers, method: 'delete' });
};
