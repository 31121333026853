import { getOfferCreatorOpen, uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { useOffersFilters } from "@/shared/lib/hooks/useOffersFilters";
import { OfferModal } from "@/widgets/OfferModal";
import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const OfferCreator = () => {
  const { partnersSelectList } = useOffersFilters();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setModalEditorOpened = useCallback(
    (value: boolean) => dispatch(uiActions.setOffersCreatorOpen(value)),
    [dispatch]
  );

  const open = useSelector(getOfferCreatorOpen, shallowEqual);

  const handleClose = useCallback(() => {
    setModalEditorOpened(false);
  }, [setModalEditorOpened]);

  const handleSave = useCallback(() => {
    navigate(ROUTES.PARTNERS.OFFERS.path);
    handleClose();
  }, [navigate, handleClose]);

  return (
    <OfferModal
      data={null}
      partnersList={partnersSelectList}
      onClose={handleClose}
      open={open}
      onSave={handleSave}
    />
  );
};
