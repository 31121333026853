import { useEffect, useState } from "react";

export function useModalOpen(
  classList: string[] = []
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [modalOpened, setModalOpened] = useState(false);
  var isMac =
    typeof window !== "undefined" &&
    navigator.platform.toUpperCase().indexOf("MAC") >= 0;

  useEffect(() => {
    document.body.classList[!modalOpened ? "remove" : "add"](
      `padding-right-${isMac ? "15" : "17"}`,
      "modal-open",
      ...classList
    );
  }, [modalOpened, classList, isMac]);

  return [modalOpened, setModalOpened];
}
