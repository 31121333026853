import { PAGINATION_SIZE } from "@/shared/consts/common";
import { pagination } from "@/shared/lib/utils/pagination";
import { useGetOffers, useGetPartners } from "@op/entities";
import { ChangeEvent, useMemo, useState } from "react";

import {
  ALL_PARTNERS_FILTER,
  EFilterTabs,
  PARTNERS_FILTERS_DEFAULT_VALUES,
} from "../../consts/offers";

export function useOffersFilters() {
  const [currentFilterTab, setCurrentFilterTab] = useState(EFilterTabs.active);
  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const [currentPartnerFilter, setCurrentPartnerFilter] =
    useState(ALL_PARTNERS_FILTER);

  const {
    data: offersData,
    isFetching: offersDataIsFetching,
    refetch: refetchOffersData,
  } = useGetOffers({
    partner_index:
      currentPartnerFilter === ALL_PARTNERS_FILTER
        ? undefined
        : currentPartnerFilter,
  });
  const { data: partnersData } = useGetPartners({});

  const partnersSelectList = useMemo(() => {
    if (!partnersData) return [];

    return Array.from(
      new Map(partnersData.map((partner) => [partner.index, partner])).values()
    ).map((partner) => ({
      value: partner.index,
      label: partner.name,
    }));
  }, [partnersData]);

  const byPartnerFilters = useMemo(() => {
    return [...PARTNERS_FILTERS_DEFAULT_VALUES, ...partnersSelectList];
  }, [partnersSelectList]);

  const handlePartnerFilterChange = (partnerName?: string) => {
    setCurrentPartnerFilter(partnerName || ALL_PARTNERS_FILTER);
    setCurrentPaginationPosition(0);
    refetchOffersData();
  };

  const handleFilterSelect = (value: number) => {
    setCurrentFilterTab(value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const offersActive = useMemo(() => {
    return offersData?.filter((item) => item.is_enabled);
  }, [offersData]);

  const offersComplete = useMemo(() => {
    return offersData?.filter((item) => !item.is_enabled);
  }, [offersData]);

  const filteredList = useMemo(() => {
    const list =
      currentFilterTab === EFilterTabs.active ? offersActive : offersComplete;

    return (
      list?.filter((offer) =>
        offer.short_title.toLowerCase().includes(searchString)
      ) ?? []
    );
  }, [currentFilterTab, offersActive, offersComplete, searchString]);

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        filteredList?.length ?? 0,
        !!next
      )
    );
  };

  return {
    offersData,
    refetchOffersData,
    currentPartnerFilter,
    handlePartnerFilterChange,
    byPartnerFilters,
    offersActive,
    offersComplete,
    filteredList,
    currentFilterTab,
    handleFilterSelect,
    searchString,
    handleSearchChange,
    handlePagination,
    currentPaginationPosition,
    offersDataIsFetching,
    partnersSelectList,
  };
}
