import { formatCategoryLabel } from "@/shared/lib/utils/formatCategoryLabel";
import { MultiSelect, Select } from "@/shared/ui/Form";
import { ECategory } from "@op/entities";
import classNames from "classnames";
import { useMemo } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IProps {
  name: string;
  control: Control<any>;
  errors: FieldErrors;
  disabled?: boolean;
  multiple?: boolean;
}

export const SelectCategoryController = ({
  name,
  errors,
  control,
  disabled,
  multiple = false,
}: IProps) => {
  const { t } = useTranslation();

  const faqTagsList = useMemo(() => {
    return Object.values(ECategory).map((category) => ({
      id: category,
      name: t(formatCategoryLabel(category)),
    }));
  }, [t]);

  const selectProps = useMemo(
    () => ({
      disabled,
      isInvalid: !!errors[name],
      className: "editor-header__rubric",
      items: faqTagsList?.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
      searchFunction: ({ label, search }: { label: string; search: string }) =>
        label.toLowerCase().includes(search),
      searchPlaceholder: t("SearchCategory"),
      button: (selectedItem?: string) => (
        <div
          className={classNames(
            "select__btn btn btn-outline black scale-up lighten-up size-s",
            !!errors[name] && "red"
          )}
        >
          <i className="btn__text select__btn-text">
            {selectedItem || t("ChooseCategory")}
          </i>
          <span className="icon icon-cursor-down select__btn-icon" />
        </div>
      ),
      option: (item: string) => (
        <div onClick={(e) => e.preventDefault()}>
          <em className="dropdown__text select__text">{item}</em>
        </div>
      ),
    }),
    [disabled, errors, faqTagsList, name, t]
  );

  return (
    <Controller
      disabled={disabled}
      name={name}
      control={control}
      rules={{ required: t("ChooseCategory") }}
      render={({ field: { value, onChange } }) =>
        multiple ? (
          <MultiSelect
            {...selectProps}
            value={value || []}
            setValue={(newValue, e?: React.SyntheticEvent) => {
              e?.preventDefault();
              onChange(newValue);
            }}
          />
        ) : (
          <Select
            {...selectProps}
            value={value}
            setValue={(newValue, e?: React.SyntheticEvent) => {
              e?.preventDefault();
              onChange(newValue);
            }}
          />
        )
      }
    />
  );
};
