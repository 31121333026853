import { $api, rtkApi } from '@op/api';

import { API_PROMO_INTERNAL_URL, API_PROMO_URL } from '../../Env';
import {
  IFaqTagAddRequest,
  IFaqTagDeleteRequest,
  IFaqTagEditRequest,
  IFaqTagListGetRequest,
  IFaqTagListGetResponse,
} from '../model/types/faqTag';

const getEndpoint = (apiUrl: string | undefined) => {
  if (!apiUrl) {
    throw new Error('No API endpoint provided');
  }

  return `${apiUrl}/faq/tags`;
};

const requestListOptions = (apiUrl: string | undefined, { token }: IFaqTagListGetRequest) => ({
  url: getEndpoint(apiUrl),
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const requestOptions = (
  apiUrl: string | undefined,
  { token, id, ...params }: IFaqTagAddRequest | IFaqTagEditRequest | IFaqTagDeleteRequest,
) => ({
  url: `${getEndpoint(apiUrl)}/${id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getFaqTags: build.query<IFaqTagListGetResponse, IFaqTagListGetRequest>({
      query: (params) => requestListOptions(API_PROMO_URL, params),
    }),
    addFaqTag: build.mutation<number, IFaqTagAddRequest>({
      query: (params) => ({
        ...requestOptions(API_PROMO_URL, params),
        method: 'post',
      }),
    }),
    editFaqTag: build.mutation<number, IFaqTagEditRequest>({
      query: (params) => ({
        ...requestOptions(API_PROMO_URL, params),
        method: 'put',
      }),
    }),
    deleteFaqTag: build.mutation<number, IFaqTagDeleteRequest>({
      query: (params) => ({
        ...requestOptions(API_PROMO_URL, params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetFaqTagsQuery: useGetFaqTagList,
  useLazyGetFaqTagsQuery: useLazyGetFaqTagList,
  useAddFaqTagMutation,
  useEditFaqTagMutation,
  useDeleteFaqTagMutation,
} = api;

export const getFaqTagList = async (params: IFaqTagListGetRequest) => {
  const faqTagList = await $api.request<IFaqTagListGetResponse>(requestListOptions(API_PROMO_INTERNAL_URL, params));
  return faqTagList.data;
};
