import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_PROMO_URL } from '../../Env';
import {
  IOffer,
  IOfferAddRequest,
  IOfferChangeStatusRequest,
  IOfferChangeStatusResponse,
  IOfferDeleteRequest,
  IOfferEditRequest,
  IOfferScopeResponse,
  IOffersListGetRequest,
} from '../model/types/offer';

const OFFERS_ENDPOINT = `${API_PROMO_URL}/offers`;
const OFFERS_SCOPES_ENDPOINT = `${API_PROMO_URL}/offer-scopes`;
const OFFERS_CHANGE_STATUS_ENDPOINT = `${API_PROMO_URL}/offers/change-status`;

const requestGetOffresListOptions = (params: IOffersListGetRequest) => {
  const queryParams = new URLSearchParams();

  if (params.partner_index !== undefined) {
    queryParams.append('PartnerIndex', params.partner_index);
  }

  if (params.is_enabled !== undefined) {
    queryParams.append('IsHidden', String(!params.is_enabled));
  }

  return {
    url: `${OFFERS_ENDPOINT}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
  };
};

const requestOffersScopesListOptions = () => ({
  url: OFFERS_SCOPES_ENDPOINT,
});

const requestChangeOfferStatusOptions = ({ token, id, ...params }: IOfferChangeStatusRequest) => ({
  url: `${OFFERS_CHANGE_STATUS_ENDPOINT}/${id ?? ''}?isEnabled=${params.is_enabled}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const requestMutateOptions = ({ token, ...params }: IOfferAddRequest | IOfferEditRequest | IOfferDeleteRequest) => ({
  url: `${OFFERS_ENDPOINT}/${params.id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getOffers: build.query<IOffer[], IOffersListGetRequest>({
      query: (params) => ({
        ...requestGetOffresListOptions(params),
      }),
    }),
    getOffersScopes: build.query<IOfferScopeResponse[], {}>({
      query: () => requestOffersScopesListOptions(),
    }),
    changeOfferStatus: build.mutation<IOfferChangeStatusResponse, IOfferChangeStatusRequest>({
      query: (params) => ({
        ...requestChangeOfferStatusOptions(params),
        method: 'PATCH',
      }),
    }),
    addOffer: build.mutation<IOfferAddRequest, IOfferAddRequest>({
      query: (params) => ({
        ...requestMutateOptions(params),
        method: 'post',
      }),
    }),
    editOffer: build.mutation<IOfferEditRequest, IOfferEditRequest>({
      query: (params) => ({
        ...requestMutateOptions(params),
        method: 'put',
      }),
    }),
    deleteOffer: build.mutation<IOfferDeleteRequest, IOfferDeleteRequest>({
      query: (params) => ({
        ...requestMutateOptions(params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetOffersQuery: useGetOffers,
  useGetOffersScopesQuery: useGetOffersScopes,
  useChangeOfferStatusMutation,
  useDeleteOfferMutation,
  useAddOfferMutation,
  useEditOfferMutation,
} = api;

export const getOffers = (params: IOffersListGetRequest): Promise<AxiosResponse<IOffer[]>> =>
  $api.request(requestGetOffresListOptions(params));

export const getOffersScopes = (): Promise<AxiosResponse<IOfferScopeResponse[]>> => $api.request(requestOffersScopesListOptions());
