import { API_JOURNAL_URL } from "@op/entities";
import { COOKIE_AUTH_TOKEN, getCookie } from "@op/ui";
import axios from "axios";

export const axiosClient = axios.create({
  baseURL: API_JOURNAL_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

axiosClient.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${getCookie(COOKIE_AUTH_TOKEN)}`;
  }
  return config;
});
