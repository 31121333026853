import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { Radio } from "@/shared/ui/Form";
import { EPartnerStatus, IOffer, useGetPartners } from "@op/entities";
import React from "react";
import { useTranslation } from "react-i18next";

interface IOffersListProps {
  offersData: IOffer[] | undefined;
  filteredList: IOffer[];
  handlePagination: (next?: boolean) => void;
  currentPaginationPosition: number;
  offersDataIsFetching: boolean;
  handleConfirm: (id?: number) => void;
  handleEdit: (id: IOffer["id"]) => void;
  handleChangeOfferStatus: (id: number, status: boolean) => void;
}

function OffersList({
  offersData,
  filteredList,
  handlePagination,
  currentPaginationPosition,
  offersDataIsFetching,
  handleConfirm,
  handleEdit,
  handleChangeOfferStatus,
}: IOffersListProps) {
  const { t } = useTranslation();
  const { data: partnersData } = useGetPartners({});

  return (
    <div className="profile__body">
      {offersDataIsFetching && filteredList.length === 0 && (
        <div>{t("Loading")}...</div>
      )}

      <style>{`
            .card-offer {
               --card-cell-font-size: 18px;
               --card-cell-font-weight: 400;
               --cell-1-width: 5%;
               --cell-2-width: 40%;
               --cell-3-width: 30%;
               --cell-4-width: 30%;
               --cell-5-width: 30%;
               gap: 20px;
                 
               & .cell-1 {
                 display: flex;
                 align-items: center;
                 gap: 8px;
                 
                 & img {
                   width: 40px;
                   height: 40px;
                   border-radius: #{$border-radius-card-small};
                   object-fit: cover;
                 }
                 
                 & .cell__title {
                   & span {
                     display: block;
                 
                     &.cell__subtitle {
                       font-size: 14px;
                       letter-spacing: -2%;
                       color: #{$grey-text};
                     }
                   }
                 }
               }
                 
               .cell-3 {
                 color: #{$grey-text};
               }
               .cell-5 {
                 & .cell__radio {
                   margin: 0;
                 }
               }
          `}</style>
      {filteredList
        .slice(
          currentPaginationPosition,
          currentPaginationPosition + PAGINATION_SIZE
        )
        .map((offer) => {
          const partner = partnersData?.find(
            (partner) => partner.index === offer.partner_index
          );

          return (
            <ItemCard
              key={offer.id}
              className="card-offer"
              cardId={offer.id}
              cardLink={offer.id}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(offer.id)}
              cells={[
                <React.Fragment key={1}>{offer.order}</React.Fragment>,
                <React.Fragment key={2}>
                  <div className="cell__title">
                    <span>
                      {offer.short_title.slice(0, 33) +
                        (offer.short_title.length > 33 ? "..." : "")}
                    </span>
                  </div>
                </React.Fragment>,
                <React.Fragment key={3}>
                  {offer.partner_name.slice(0, 33) +
                    (offer.partner_name.length > 33 ? "..." : "")}
                </React.Fragment>,
                <React.Fragment key={4}>{t(offer.type)}</React.Fragment>,
                <React.Fragment key={5}>
                  <div className="form__block profile-modal__radio-block cell__radio">
                    <Radio
                      value={offer.is_enabled}
                      disabled={
                        partner?.partner_status ===
                        EPartnerStatus.publishRemoved
                      }
                      onChange={() =>
                        handleChangeOfferStatus(offer.id, !offer.is_enabled)
                      }
                      label={
                        offer.is_enabled ? t("OfferActive") : t("OfferComplete")
                      }
                    />
                    {partner?.partner_status ===
                      EPartnerStatus.publishRemoved && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {t("PartnerHidden")}
                      </p>
                    )}
                  </div>
                </React.Fragment>,
              ]}
            />
          );
        })}
      <div className="profile__pagination-bottom">
        <PaginationArrows
          onClick={handlePagination}
          currentPosition={currentPaginationPosition}
          paginationSize={PAGINATION_SIZE}
          catalogLength={filteredList?.length ?? 0}
        />
      </div>
    </div>
  );
}

export default OffersList;
