import { getFaqTagsCreatorOpen, uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { FaqTagsModal } from "@/widgets/FaqTagsModal";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const FaqTagsCreator = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useSelector(getFaqTagsCreatorOpen);

  const handleClose = useCallback(() => {
    dispatch(uiActions.setFaqTagsCreatorOpen(false));
  }, [dispatch]);

  const handleSave = useCallback(() => {
    navigate(ROUTES.FAQ.TAGS.path);
    handleClose();
  }, [navigate, handleClose]);

  return <FaqTagsModal open={open} onClose={handleClose} onSave={handleSave} />;
};
