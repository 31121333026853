import { ImageUploader } from "@/components/ImageUploader";
import { Input, Radio } from "@/shared/ui/Form";
import { EPartnerStatus, IPartner } from "@op/entities";
import React, { ChangeEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { cyrillicToTranslit, getSeoDefaultValue } from "../lib/utils";

interface IPartnerModalFormProps {
  data: Nullable<IPartner>;
  isLoading: boolean;
  onSubmit: (formData: TFormFields) => Promise<void>;
  onDirtyChange: (isDirty: boolean) => void;
}

type TFormFields = Omit<Partial<IPartner>, "offers">;

export const defaultValues: TFormFields = {
  description: "",
  index: "",
  is_big: false,
  logo_image: "",
  name: "",
  partner_status: EPartnerStatus.published,
  slogan: "",
  header: "",
  seo_description: "",
  seo_title: "",
};

const PartnerFormModal = ({
  data,
  isLoading,
  onDirtyChange,
  onSubmit,
}: IPartnerModalFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setValue("name", newName);
    onDirtyChange(true);
    if (!dirtyFields.index && !data) {
      setValue(
        "index",
        cyrillicToTranslit.transform(newName, "-").toLowerCase()
      );
    }
    if (!dirtyFields.seo_title && !data) {
      setValue("seo_title", getSeoDefaultValue("title", newName));
    }
    if (!dirtyFields.seo_description && !data) {
      setValue("seo_description", getSeoDefaultValue("description", newName));
    }
    if (!dirtyFields.header && !data) {
      setValue("header", getSeoDefaultValue("header", newName));
    }
  };

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  useEffect(() => {
    onDirtyChange(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields, onDirtyChange, Object.keys(dirtyFields).length]);

  return (
    <form className="profile-modal__holder" onSubmit={handleSubmit(onSubmit)}>
      <div className="profile-modal__header">
        {data ? (
          <span className="profile-modal__header-title">
            {t("PartnersEdit")}
          </span>
        ) : (
          <span className="profile-modal__header-title">
            {t("PartnersAdd")}
          </span>
        )}
        <p className="profile-modal__header-text">
          {t("PartnersAddDescription")}
        </p>
      </div>
      <div className="form profile-modal__form">
        <div className="form__block">
          <span className="label">{t("PartnerName")}</span>
          <Input
            {...register("name", {
              required: t("FieldRequired"),
              maxLength: {
                value: 55,
                message: t("TextMaxLength", { maxLength: 55 }),
              },
            })}
            isInvalid={!!errors.name}
            onChange={handleNameChange}
            errorText={errors.name?.message}
            placeholder={t("PartnerName")}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("PartnerDescription")}</span>
          <Input
            {...register("description", {
              required: t("FieldRequired"),
              maxLength: {
                value: 100,
                message: t("TextMaxLength", { maxLength: 100 }),
              },
            })}
            isInvalid={!!errors.description}
            errorText={errors.description?.message}
            placeholder={t("PartnerDescription")}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("PartnerSlogan")}</span>
          <Input
            {...register("slogan", {
              maxLength: {
                value: 73,
                message: t("TextMaxLength", { maxLength: 73 }),
              },
            })}
            isInvalid={!!errors.slogan}
            errorText={errors.slogan?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("PartnerLogo")}</span>
          <div className="editor-header__image-uploader">
            <Controller
              name="logo_image"
              control={control}
              rules={{ required: t("UploadCover") }}
              render={({ field: { value, onChange } }) => (
                <ImageUploader
                  value={value}
                  folder="partners"
                  onChange={onChange}
                  isInvalid={!!errors.logo_image}
                />
              )}
            />
          </div>
        </div>
        <div className="form__block">
          <span className="label">{t("PartnerType")}</span>
          <Controller
            name={"is_big"}
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <Radio
                  label={!!value ? t("PartnerIsBig") : t("PartnerIsSmall")}
                  value={!!value}
                  onChange={onChange}
                />
              </>
            )}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("PartnerIndex")}</span>
          <Input
            {...register("index", { required: true })}
            isInvalid={!!errors.index}
            errorText={errors.index?.message}
            disabled={!!data}
          />
        </div>

        <div className="form__block">
          <span className="label">{t("PartnerSeoTitle")}</span>
          <Input
            {...register("seo_title", { required: true })}
            isInvalid={!!errors.seo_title}
            errorText={errors.seo_title?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("PartnerSeoDescription")}</span>
          <Input
            {...register("seo_description", { required: true })}
            isInvalid={!!errors.seo_description}
            errorText={errors.seo_description?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("PartnerSeoHeader")}</span>
          <Input
            {...register("header", { required: true })}
            isInvalid={!!errors.header}
            errorText={errors.header?.message}
          />
        </div>

        <div className="form__block">
          <span className="label">{t("PartnerType")}</span>
          <Controller
            name={"partner_status"}
            control={control}
            render={({ field: { value, onChange } }) => {
              const isPublished = value === EPartnerStatus.published;

              return (
                <>
                  <Radio
                    label={isPublished ? t("PartnerPublish") : t("PartnerHide")}
                    value={isPublished}
                    onChange={(newValue) => {
                      onChange(
                        newValue
                          ? EPartnerStatus.published
                          : EPartnerStatus.publishRemoved
                      );
                    }}
                  />
                </>
              );
            }}
          />
        </div>

        <button className="btn btn-fond scale-up lighten-up btn-full blue">
          {data ? (
            <i className="btn__text">{isLoading ? t("Saving") : t("Save")}</i>
          ) : (
            <i className="btn__text">
              {isLoading ? t("Saving") : t("PartnersAdd")}
            </i>
          )}
        </button>
      </div>
    </form>
  );
};

export default PartnerFormModal;
