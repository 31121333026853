import { getSeoInfoCreatorOpen, uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { SeoInfoModal } from "@/widgets/SeoInfoModal";
import { useGetSeoInfoList } from "@op/entities";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const SeoInfoCreator = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useSelector(getSeoInfoCreatorOpen);
  const location = useLocation();

  const { refetch } = useGetSeoInfoList(undefined, {
    skip: location.pathname !== ROUTES.GENERAL.SEO_TAGS.path,
  });

  const handleClose = useCallback(() => {
    dispatch(uiActions.setSeoInfoCreatorOpen(false));
  }, [dispatch]);

  const handleSave = useCallback(() => {
    refetch();
    navigate(ROUTES.GENERAL.SEO_TAGS.path);
  }, [navigate, refetch]);

  return (
    <SeoInfoModal
      open={open}
      onClose={handleClose}
      data={null}
      onSave={handleSave}
    />
  );
};
