import CyrillicToTranslit from "cyrillic-to-translit-js";

export const getSeoDefaultValue = (
  type: "header" | "title" | "description",
  name?: string
) => {
  const partnerName = name || "[имя партнера]";

  switch (type) {
    case "header":
      return `Бонус от партнера ${partnerName}`;
    case "title":
      return `Предложения от ${partnerName} партнера Онлайн Патента`;
    case "description":
      return `Специальные предложения от ${partnerName} - партнер Онлайн Патента`;
    default:
      return "";
  }
};

export const cyrillicToTranslit = CyrillicToTranslit();
