/* eslint-disable camelcase */
import { IOffer } from '@op/entities';

import { IAuthorizedRequest } from '../../../shared/types/entities';

// eslint-disable-next-line no-shadow
export enum EPartnerStatus { // статус партнера опубликован/не опубликован
  published = 'published',
  publishRemoved = 'publishRemoved',
}

export interface IPartner {
  id: number;
  index: string;
  name: string;
  logo_image: string;
  description: string;
  slogan: string; // отображает детальную информацию о предложениях на детальной странице или в модалке предложения
  is_big: boolean; // большой ли партнер (есть ли у него детальная страница или только модалка у предложения)
  offers: IOffer[]; // список предложений
  partner_status: EPartnerStatus;
  header: string; // seo заголовок для h1
  seo_title: string;
  seo_description: string;
}

export type IPartnersListGetResponse = IPartner[];

export interface IPartnersDetailGetRequest {
  index: IPartner['index'];
}

export type IPartnerMutationResponse = string;

export interface IPartnerDeleteResponse {
  id: number;
}

export interface IPartnerChangeStatusRequest extends IAuthorizedRequest, Pick<IPartner, 'id' | 'partner_status'> {}

export interface IPartnerDeleteRequest extends IAuthorizedRequest, Pick<IPartner, 'id'> {}

export interface IPartnerAddRequest extends IAuthorizedRequest, Partial<IPartner> {}

export interface IPartnerEditRequest extends IAuthorizedRequest, Partial<IPartner> {}
