import moment from "moment";

import { DATE_FORMAT_UTC } from "../../shared/consts/common";

export enum EFilterTabs {
  all,
  draft,
}

export enum EDateOptions {
  all,
  month,
  week,
}

export const DATE_OPTIONS = [
  { value: EDateOptions.week, label: "DateFilterWeek" },
  { value: EDateOptions.month, label: "DateFilterMonth" },
  { value: EDateOptions.all, label: "DateFilterAll" },
];

export const FILTER_TABS = [
  { value: EFilterTabs.all, label: "All" },
  { value: EFilterTabs.draft, label: "Draft" },
];

export type flagsKeysTypes = keyof Pick<
  ArticleResponse,
  "is_main" | "is_important" | "is_special" | "is_draft"
>;

export const flagsDictionary: Record<flagsKeysTypes, string> = {
  is_main: "Main",
  is_important: "Important",
  // is_best: 'Best',
  is_special: "Special",
  is_draft: "Draft",
};

export const defaultValues: Omit<
  ArticleDetail,
  "article_id" | "content" | "author_id" | "tagslug"
> = {
  // article_id: number,
  // content: '',
  // author_id: 1,
  // tagslug: ETags.farma,

  date: moment(new Date()).startOf("day").format(DATE_FORMAT_UTC),
  created_date_time: moment(new Date()).startOf("day").format(DATE_FORMAT_UTC),
  modified_date_time: moment(new Date()).startOf("day").format(DATE_FORMAT_UTC),

  article_index: "",
  article_name: "",
  article_title: "",
  cover_image: "",
  tag_name: "",
  time_to_read: "",
  is_draft: false,
  is_best: false,
  is_important: false,
  is_main: false,
  is_special: false,
  likes: 0,
  view_counts: 0,
  seo_description: "",
  seo_title: "",
};

export const urlPattern = /^([a-zA-Z0-9_-]+)$/g;
