export enum EFilterTabs {
  active,
  complete,
}

export const FILTER_TABS = [
  { value: EFilterTabs.active, label: "Active" },
  { value: EFilterTabs.complete, label: "Completed" },
];

export const ALL_PARTNERS_FILTER = "AllPartners";

export const PARTNERS_FILTERS_DEFAULT_VALUES = [
  { value: ALL_PARTNERS_FILTER, label: ALL_PARTNERS_FILTER },
];
