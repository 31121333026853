import { getAuthorCreatorOpen, uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { AuthorModal } from "@/widgets/AuthorModal";
import { useGetAuthorList } from "@op/entities";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthorCreator = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useSelector(getAuthorCreatorOpen);
  const location = useLocation();

  const { refetch } = useGetAuthorList(undefined, {
    skip: location.pathname !== ROUTES.GENERAL.AUTHORS.path,
  });

  const handleClose = useCallback(() => {
    dispatch(uiActions.setAuthorCreatorOpen(false));
  }, [dispatch]);

  const handleSave = useCallback(() => {
    refetch();
    navigate(ROUTES.GENERAL.AUTHORS.path);
  }, [navigate, refetch]);

  return <AuthorModal open={open} onClose={handleClose} onSave={handleSave} />;
};
