import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UISchema } from "../types/UISchema";

const initialState: UISchema = {
  journalArticlesModalEditorOpen: false,
  faqArticlesModalEditorOpen: false,
  authorCreatorOpen: false,
  seoInfoCreatorOpen: false,
  journalTagCreatorOpen: false,
  faqTagsCreatorOpen: false,
  reviewCreatorOpen: false,
  partnersCreatorOpen: false,
  offersCreatorOpen: false,
  expertCreatorOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setJournalArticlesModalEditorOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.journalArticlesModalEditorOpen = payload;
    },
    setFaqArticlesModalEditorOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.faqArticlesModalEditorOpen = payload;
    },
    setAuthorCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.authorCreatorOpen = payload;
    },
    setSeoInfoCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.seoInfoCreatorOpen = payload;
    },
    setJournalTagCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.journalTagCreatorOpen = payload;
    },
    setFaqTagsCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.faqTagsCreatorOpen = payload;
    },
    setReviewCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.reviewCreatorOpen = payload;
    },
    setPartnersCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.partnersCreatorOpen = payload;
    },
    setOffersCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.offersCreatorOpen = payload;
    },
    setExpertCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.expertCreatorOpen = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { actions: uiActions } = uiSlice;
export const { reducer: uiReducer } = uiSlice;
