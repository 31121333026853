import { getExpertCreatorOpen, uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { ExpertsModal } from "@/widgets/ExpertsModal";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const ExpertsCreator = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useSelector(getExpertCreatorOpen);

  const handleClose = useCallback(() => {
    dispatch(uiActions.setExpertCreatorOpen(false));
  }, [dispatch]);

  const handleSave = useCallback(() => {
    navigate(ROUTES.GENERAL.EXPERT.path);
    handleClose();
  }, [navigate, handleClose]);

  return (
    <ExpertsModal
      open={open}
      onClose={handleClose}
      onSave={handleSave}
      data={null}
    />
  );
};
