import userStore from "@/app/providers/MobxStore/model/UserStore";
import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import { ConfirmModal } from "@/features/Modal";
import { getFaqTagsCreatorOpen } from "@/features/UI";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { useModalConfirm } from "@/shared/lib/hooks/useModalComfirm";
import { useModalOpen } from "@/shared/lib/hooks/useModalOpen";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import Input from "@/shared/ui/Form/ui/Input";
import { FaqTagsModal } from "@/widgets/FaqTagsModal";
import {
  IFaqTag,
  useDeleteFaqTagMutation,
  useGetFaqTagList,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function FaqTags() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] = useState<IFaqTag>();
  const [modalConfirm, setModalConfirm] = useModalConfirm();
  const faqTagsCreatorOpen = useSelector(getFaqTagsCreatorOpen);

  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const {
    data: faqTagsList,
    isFetching: faqTagsListIsFetching,
    refetch: refetchFaqTagsList,
    error: getFaqTagsListError,
  } = useGetFaqTagList({
    token: userStore.token ?? "",
  });

  const [deleteFaqTag] = useDeleteFaqTagMutation();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        faqTagsList?.length ?? 0,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await deleteFaqTag({
        id: modalConfirm.id,
        token: userStore.token ?? "",
      });
      if ("error" in response) {
        console.error(response.error);
        notify((response.error as FetchBaseQueryError).data);
        return;
      }

      if ("data" in response) {
        setModalConfirm({ isOpen: false, id: null });
        refetchFaqTagsList();
      }
    }
  };

  const handleEdit = (data: IFaqTag) => {
    if (data) {
      setModalEditorOpened(true);
      setModalEditorData(data);
    }
  };

  const handleAdd = () => {
    setModalEditorOpened(true);
    setModalEditorData(undefined);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await refetchFaqTagsList();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  useEffect(() => {
    if (!faqTagsCreatorOpen) {
      refetchFaqTagsList();
    }
  }, [faqTagsCreatorOpen, refetchFaqTagsList]);

  useEffect(() => {
    if (getFaqTagsListError) {
      notify(t("AnErrorOcurredLoadingData"), "getFaqTagsListError");
    }
  }, [getFaqTagsListError, t]);

  const filteredItems = faqTagsList?.filter((item) =>
    item.name.toLowerCase().includes(searchString)
  );

  if (faqTagsListIsFetching) {
    return (
      <>
        <div className="profile__title-block">
          <strong className="profile__title">{t("TagsList")}</strong>
        </div>
        <div className="profile__body profile-rubrics">
          <div>{t("Loading")}...</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("TagsList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("TagAdd")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchByTags")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={faqTagsList?.length ?? 0}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-rubrics">
        {filteredItems
          ?.slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((item) => (
            <ItemCard
              key={item.id}
              className="card-rubric"
              cardId={item.id}
              cardLink={item.index}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(item)}
              cells={[
                <React.Fragment key={1}>{item.name}</React.Fragment>,
                <React.Fragment key={1}>
                  {item.is_enabled ? t("TagShow") : t("TagHidden")}
                </React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={faqTagsList?.length ?? 0}
          />
        </div>
      </div>

      <FaqTagsModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("TagDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(FaqTags);
