import { UserStore } from "@/app/providers/MobxStore";
import CardArticleInfo from "@/components/CardArticleInfo";
import CardArticleThumbnail from "@/components/CardArticleThumbnail";
import ItemCard from "@/components/ItemCard";
import PaginationArrows from "@/components/PaginationArrows";
import { ConfirmModal } from "@/features/Modal";
import { getFaqArticlesModalEditorOpen } from "@/features/UI";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { useModalConfirm } from "@/shared/lib/hooks/useModalComfirm";
import { useModalOpen } from "@/shared/lib/hooks/useModalOpen";
import { isFutureDate } from "@/shared/lib/utils/isFutureDate";
import { notify } from "@/shared/lib/utils/notify";
import { pagination } from "@/shared/lib/utils/pagination";
import { Input, Select } from "@/shared/ui/Form";
import { FaqArticlesModalEditor as ModalEditor } from "@/widgets/FaqArticlesModalEditor";
import {
  IFaqArticle,
  IFaqArticleDetail,
  PROMO_URL,
  useDeleteFaqArticleMutation,
  useGetFaqArticleList,
  useLazyGetFaqArticleDetail,
  useRestoreFaqArticleMutation,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DATE_OPTIONS,
  EDateOptions,
  EFilterTabs,
  FILTER_TABS,
} from "../model/consts/faqArticlesConsts";

function FaqArticles() {
  const { t } = useTranslation();
  const faqArticlesModalEditorOpen = useSelector(getFaqArticlesModalEditorOpen);
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalConfirmDelete, setModalConfirmDelete] = useModalConfirm();
  const [modalConfirmRestore, setModalConfirmRestore] = useModalConfirm();
  const [modalEditorData, setModalEditorData] = useState<IFaqArticleDetail>();
  const [searchString, setSearchString] = useState("");
  const [currentFilterTab, setCurrentFilterTab] = useState(EFilterTabs.all);
  const [currentDateFilter, setCurrentDateFilter] = useState(EDateOptions.all);
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const {
    data: articlesData,
    isFetching: articlesDataIsFetching,
    refetch: refetchArticlesData,
    error: getFaqArticlesListError,
  } = useGetFaqArticleList({
    token: UserStore.token!,
  });

  const [getFaqArticleDetail] = useLazyGetFaqArticleDetail({});

  const [deleteFaqArticle] = useDeleteFaqArticleMutation();
  const [restoreFaqArticle] = useRestoreFaqArticleMutation();

  const articles = useMemo(() => {
    return articlesData?.items?.filter((item) => item.is_enabled);
  }, [articlesData]);

  const articlesDeleted = useMemo(() => {
    return articlesData?.items?.filter((item) => !item.is_enabled);
  }, [articlesData]);

  const articlesDraft = useMemo(() => {
    return articlesData?.items?.filter((item) => item.is_draft);
  }, [articlesData]);

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        articlesData?.items?.length ?? 0,
        !!next
      )
    );
  };

  const handleFilterSelect = (value: number) => {
    setCurrentFilterTab(value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handleDateFilterChange = (date?: EDateOptions) => {
    if (date != null) {
      setCurrentDateFilter(date);
      setCurrentPaginationPosition(0);
    }
  };

  const handleConfirmDelete = (id?: number) => {
    id
      ? setModalConfirmDelete({ isOpen: true, id: id })
      : setModalConfirmDelete({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirmDelete.id) {
      const response = await deleteFaqArticle({
        id: modalConfirmDelete.id,
        token: UserStore.token!,
      });
      if ("error" in response) {
        notify((response.error as FetchBaseQueryError).data);
        return;
      }
      if ("data" in response) {
        setModalConfirmDelete({ isOpen: false, id: null });
        refetchArticlesData();
      }
    }
  };

  const handleConfirmRestore = (id?: number) => {
    id
      ? setModalConfirmRestore({ isOpen: true, id: id })
      : setModalConfirmRestore({
          isOpen: false,
          id: null,
        });
  };

  const handleRestore = async () => {
    if (modalConfirmRestore.id) {
      const response = await restoreFaqArticle({
        id: modalConfirmRestore.id,
        token: UserStore.token!,
      });
      if ("error" in response) {
        notify(t("AnErrorOcurred"));
        return;
      }
      if ("data" in response) {
        setModalConfirmRestore({ isOpen: false, id: null });
        refetchArticlesData();
      }
    } else {
      notify(t("ArticleNotFound"));
    }
  };

  const handleEdit = async (data: IFaqArticle) => {
    const response = await getFaqArticleDetail({
      index: data.index,
      token: UserStore.token!,
    });
    if ("data" in response) {
      setModalEditorData(response.data);
      setModalEditorOpened(true);
    }
  };

  const handleClick = async ({ index, tag_index }: IFaqArticle) => {
    window.open(`${PROMO_URL}/faq/${tag_index}/${index}/`, "_blank");
  };

  const handleAdd = async () => {
    setModalEditorData(undefined);
    setModalEditorOpened(true);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await refetchArticlesData();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  const filteredList = useMemo(() => {
    const list =
      currentFilterTab === EFilterTabs.deleted
        ? articlesDeleted
        : currentFilterTab === EFilterTabs.draft
        ? articlesDraft
        : articles;

    return list
      ?.filter((article) => article.title.toLowerCase().includes(searchString))
      .filter((article) => {
        const articleAge = moment().diff(
          moment(article.publication_date_time_utc),
          "days"
        );
        switch (currentDateFilter) {
          case EDateOptions.week:
            return articleAge <= 7;
          case EDateOptions.month:
            return articleAge <= 30;
          default:
            return true;
        }
      });
  }, [
    articles,
    articlesDeleted,
    articlesDraft,
    currentDateFilter,
    currentFilterTab,
    searchString,
  ]);

  const paginatedList = useMemo(
    () =>
      filteredList?.slice(
        currentPaginationPosition,
        currentPaginationPosition + PAGINATION_SIZE
      ),
    [currentPaginationPosition, filteredList]
  );

  useEffect(() => {
    if (!faqArticlesModalEditorOpen) {
      refetchArticlesData();
    }
  }, [faqArticlesModalEditorOpen, refetchArticlesData]);

  useEffect(() => {
    if (getFaqArticlesListError) {
      notify(t("AnErrorOcurredLoadingData"), "getFaqArticlesListError");
    }
  }, [getFaqArticlesListError, t]);

  if (articlesDataIsFetching) {
    return (
      <>
        <div className="profile__title-block">
          <strong className="profile__title">{t("ArticlesList")}</strong>
        </div>
        <div className="profile__body profile-rubrics">
          <div>{t("Loading")}...</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("ArticlesList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("AddArticle2")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <ul className="profile__tabs">
            {FILTER_TABS.map((tab) => (
              <li
                className={classNames(
                  "profile__tab",
                  currentFilterTab === tab.value && "is-active"
                )}
                key={tab.label}
                onClick={() => handleFilterSelect(tab.value)}
              >
                <div className="profile__tab-holder">
                  <strong className="profile__tab-name">{t(tab.label)}</strong>
                  <span className="profile__tab-number">
                    {tab.value === EFilterTabs.draft
                      ? articlesDraft?.length ?? 0
                      : tab.value === EFilterTabs.deleted
                      ? articlesDeleted?.length ?? 0
                      : articles?.length ?? 0}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <div className="search-input profile__search">
              <Input
                placeholder={t("SearchByArticles")}
                className="input_stroke profile__search-input"
                value={searchString}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="profile__control-block">
            <Select
              className="profile__filter"
              dropDownClassName="bottom bottom-end"
              value={currentDateFilter}
              setValue={handleDateFilterChange}
              items={DATE_OPTIONS}
              button={(label) => (
                <button className="btn btn-outline lighten-up scale-up black">
                  <i className="btn__text select__btn-text">{t(label!)}</i>
                  <span className="icon icon-cursor-down select__btn-icon"></span>
                </button>
              )}
              option={(item) => (
                <em className="dropdown__text select__text">{t(item)}</em>
              )}
            />
          </div>
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={filteredList?.length ?? 0}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-articles">
        {paginatedList?.map((article) => (
          <ItemCard
            key={article.id}
            className={classNames(
              "card-article",
              article.is_draft && "is-unpublished"
            )}
            isDeleted={!article.is_enabled}
            cardId={article.id}
            cardLink={article.index}
            onDelete={article.is_enabled ? handleConfirmDelete : undefined}
            onRestore={!article.is_enabled ? handleConfirmRestore : undefined}
            onEdit={() => handleEdit(article)}
            onClick={() => handleClick(article)}
            cells={[
              <CardArticleThumbnail key={0} thumbailUrl={article.image} />,
              <CardArticleInfo
                key={1}
                title={article.title}
                authorId={article.author_id}
                articleIndex={article.index}
              />,
              <React.Fragment key={2}>
                <p>
                  {moment(article.publication_date_time_utc).format(
                    "DD MMMM YYYY"
                  )}
                </p>
                {isFutureDate(article.publication_date_time_utc) && (
                  <p className="profile-articles__status red">
                    {t("Deffered")}
                  </p>
                )}
              </React.Fragment>,
            ]}
          />
        ))}
        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={filteredList?.length ?? 0}
          />
        </div>
      </div>
      <ModalEditor
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("ArticleDeleteConfirm")}
        modalOpen={modalConfirmDelete.isOpen}
        setModalOpen={handleConfirmDelete}
        onConfirm={handleDelete}
      />
      <ConfirmModal
        title={t("Restore")}
        text={t("ArticleRestoreConfirm")}
        modalOpen={modalConfirmRestore.isOpen}
        setModalOpen={handleConfirmRestore}
        onConfirm={handleRestore}
      />
    </>
  );
}

export default withTranslation()(FaqArticles);
