import { EOfferType } from "@op/entities";

import { ISelectItem, TOfferModalFormFields } from "./types/offerModalFrom";

export const defaultOfferModalFormValues: TOfferModalFormFields = {
  full_title: "",
  short_title: "",
  description: "",
  highlight_offer: "",
  is_enabled: true,
  offer_scopes_id: undefined,
  amount: 0,
  order: 0,
  promo_code: "",
  type: undefined,
  partner_index: undefined,
  partner_site_url: "",
};

export const OFFER_TYPE_SELECT_LIST: ISelectItem[] = [
  { value: EOfferType.discount, label: EOfferType.discount },
  { value: EOfferType.promocode, label: EOfferType.promocode },
  { value: EOfferType.trial, label: EOfferType.trial },
];
