import useOnClickOutside from "@/shared/lib/hooks/useOnClickOutside";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface ISelect<L, V> {
  value?: V[];
  setValue: (value: V[] | undefined) => void;
  items?: { value: V; label: L; isDefault?: boolean }[];
  searchPlaceholder?: string;
  buttonPlaceholder?: L;
  button: (label?: L) => React.ReactElement;
  option?: (label: L) => React.ReactElement;
  searchFunction?: ({ label, search }: { label: L; search: string }) => boolean;
  className?: string;
  isInvalid?: boolean;
  dropDownClassName?: string;
  disabled?: boolean;
  labelJoinString?: string;
  maxSelectedItems?: number;
}

const MultiSelect = <L extends unknown, V extends string | number>({
  className,
  dropDownClassName,
  searchPlaceholder,
  items,
  value,
  setValue,
  buttonPlaceholder,
  button,
  option,
  isInvalid,
  searchFunction,
  disabled,
  labelJoinString = ", ",
  maxSelectedItems,
}: ISelect<L, V>) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(buttonRef, (event) => {
    if (!menuRef.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  });

  let filteredItems = items || [];
  if (searchFunction) {
    filteredItems = filteredItems.filter(({ label }) =>
      searchFunction({ label, search: searchValue.toLowerCase() })
    );
  }

  const getSelectedLabels = (): L | undefined => {
    if (!value?.length) return buttonPlaceholder;

    const selectedLabels = items
      ?.filter((item) => value.includes(item.value))
      ?.map((item) => item.label);

    if (!selectedLabels?.length) return buttonPlaceholder;

    // Если L это строка, объединяем их
    if (typeof selectedLabels[0] === "string") {
      return selectedLabels.join(labelJoinString) as L;
    }

    // Если L это не строка, возвращаем первую метку
    return selectedLabels[0];
  };

  return (
    <label
      className={classNames(
        "select multiselect",
        className,
        isOpen && "is-open",
        isInvalid && "is-invalid",
        disabled && "is-disabled"
      )}
    >
      <div ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
        {button(getSelectedLabels())}
      </div>

      {option && (
        <div className={`dropdown ${dropDownClassName}`} ref={menuRef}>
          {searchFunction && (
            <div className="dropdown__search">
              <input
                className="dropdown__search-input"
                type="text"
                value={searchValue}
                placeholder={searchPlaceholder}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </div>
          )}
          <ul className="dropdown__list">
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <li
                  className={classNames("dropdown__item select__item", {
                    "is-active":
                      value?.includes(item.value) || (!value && item.isDefault),
                  })}
                  key={index}
                >
                  <button
                    type="button"
                    className="dropdown__btn"
                    onClick={() => {
                      const newValue = value || [];
                      if (newValue.includes(item.value)) {
                        setValue(newValue.filter((v) => v !== item.value));
                      } else {
                        if (
                          maxSelectedItems &&
                          newValue.length >= maxSelectedItems
                        ) {
                          return;
                        }
                        setValue([...newValue, item.value]);
                      }
                    }}
                  >
                    {option(item.label)}
                  </button>
                </li>
              ))
            ) : (
              <li className="dropdown__item select__item select__item-none">
                <span className="dropdown__btn  select__text-none">
                  {t("NothingFound")}
                </span>
              </li>
            )}
          </ul>
        </div>
      )}

      {!option && (
        <select
          multiple
          className="dropdown__select"
          value={(value || []).map(String)}
          onChange={(event) => {
            const selectedOptions = Array.from(
              event.target.selectedOptions,
              (option) => option.value as V
            );
            setValue(selectedOptions);
          }}
        >
          {filteredItems.map((item) => (
            <option value={item.value} key={item.value}>
              {typeof item.label === "string"
                ? item.label
                : JSON.stringify(item.label)}
            </option>
          ))}
        </select>
      )}
    </label>
  );
};

export default MultiSelect;
