import JournalArticlesModalEditor from "@/components/JournalArticles/JournalArticlesModalEditor";
import ProfileAside from "@/components/ProfileAside/ProfileAside";
import ProfileHeader from "@/components/ProfileHeader";
import { getJournalArticlesModalEditorOpen } from "@/features/UI";
import { uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { AuthorCreator } from "./AuthorCreator";
import { ExpertsCreator } from "./ExpertsCreator";
import { FaqArticlesCreator } from "./FaqArticlesCreator";
import { FaqTagsCreator } from "./FaqTagsCreator";
import { JournalTagsCreator } from "./JournalTagsCreator";
import { OfferCreator } from "./OfferCreator";
import { PartnerCreator } from "./PartnerCreator";
import { ReviewsCreator } from "./ReviewsCreator";
import { SeoInfoCreator } from "./SeoInfoCreator";

const ProfileContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const journalArticlesModalEditorOpen = useSelector(
    getJournalArticlesModalEditorOpen
  );

  const handleJouralArticleCreatorModalOnSave = useCallback(() => {
    navigate(ROUTES.HOME.path);
  }, [navigate]);

  const handleJournalArticlesModalEditorClose = useCallback(() => {
    dispatch(uiActions.setJournalArticlesModalEditorOpen(false));
  }, [dispatch]);

  return (
    <>
      <div className="profile-section">
        <div className="wrap">
          <div className="profile profile-page">
            <ProfileHeader />
            <div className="profile-page__columns">
              <ProfileAside />
              <div className="profile-page__center">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <JournalArticlesModalEditor
        open={journalArticlesModalEditorOpen}
        onClose={handleJournalArticlesModalEditorClose}
        data={undefined}
        onSave={handleJouralArticleCreatorModalOnSave}
      />
      <AuthorCreator />
      <JournalTagsCreator />
      <SeoInfoCreator />
      <ReviewsCreator />
      <ExpertsCreator />
      <FaqArticlesCreator />
      <FaqTagsCreator />
      <OfferCreator />
      <PartnerCreator />
    </>
  );
};

export default observer(ProfileContainer);
