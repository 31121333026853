import classNames from "classnames";
import { useLocation } from "react-router-dom";

import Link from "../../shared/ui/Link";

interface IProps {
  title: string;
  items: {
    name: string;
    link: string;
    onPlusClick?: () => void;
  }[];
}

export const ProfileAsideGroup = ({ title, items }: IProps) => {
  const location = useLocation();

  return (
    <>
      <div className="aside__menu-block">
        <p className="profile-page__aside-title">{title}</p>
        <ul className="aside-menu article-page__aside-menu">
          {items.map((menuItem) => (
            <li
              key={menuItem.name}
              className={classNames([
                "aside-menu__item",
                location.pathname === menuItem.link && "is-active",
              ])}
            >
              <div className="aside-menu__link">
                <span className="icon icon-publications aside-menu__icon" />
                <Link to={menuItem.link} className="aside-menu__item-name">
                  {menuItem.name}
                </Link>
                {menuItem.onPlusClick && (
                  <button
                    type="button"
                    className="btn-icon aside-menu__item-btn"
                    onClick={menuItem.onPlusClick}
                  >
                    <span className="icon icon-plus-black" />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
