import { $api, rtkApi } from '@op/api';

import { API_PROMO_LEGACY_INTERNAL_URL, API_PROMO_LEGACY_URL } from '../../Env';
import { IMktuDetailRequest, IMktuDetailResponse } from '../model/types/mktuDetail';

const mktuDetailOptions = (apiUrl: string | undefined, { id }: IMktuDetailRequest) => {
  if (!apiUrl) {
    throw new Error('No API endpoint provided');
  }

  return {
    // url: `${API_PROMO_URL}/mktuClass/${id}`, // future API
    url: `${apiUrl}/static/MktuClasses/${id}.json`,
  };
};

const mktuDetailApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getMktuDetail: build.query<IMktuDetailResponse, IMktuDetailRequest>({
      query: (params) => mktuDetailOptions(API_PROMO_LEGACY_URL, params),
    }),
  }),
});

export const useGetMktuDetail = mktuDetailApi.useGetMktuDetailQuery;
export const useLazyGetMktuDetail = mktuDetailApi.useLazyGetMktuDetailQuery;

export const getMktuDetail = async (params: IMktuDetailRequest) => {
  const mktuDetail = await $api.request<IMktuDetailResponse>(mktuDetailOptions(API_PROMO_LEGACY_INTERNAL_URL, params));
  return mktuDetail.data;
};
