import { ConfirmModal } from "@/features/Modal";
import { useOffersFilters } from "@/shared/lib/hooks/useOffersFilters";
import { OfferModal } from "@/widgets/OfferModal";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { useOfferActions } from "../lib/hooks/useOfferActions";
import OffersControls from "./OfferControls";
import OfferTitleBlock from "./OfferTitleBlock";
import OffersList from "./OffersList";

function Offers() {
  const { t } = useTranslation();

  const {
    offersData,
    currentPartnerFilter,
    handlePartnerFilterChange,
    byPartnerFilters,
    offersActive,
    offersComplete,
    filteredList,
    currentFilterTab,
    handleFilterSelect,
    searchString,
    handleSearchChange,
    handlePagination,
    currentPaginationPosition,
    offersDataIsFetching,
    partnersSelectList,
  } = useOffersFilters();

  const {
    handleAdd,
    handleConfirm,
    handleEdit,
    handleChangeOfferStatus,
    modalEditorOpened,
    setModalEditorOpened,
    modalEditorData,
    handleModalEditorOnSave,
    modalConfirm,
    handleDelete,
  } = useOfferActions();

  return (
    <>
      <OfferTitleBlock
        byPartnerFilters={byPartnerFilters}
        currentPartnerFilter={currentPartnerFilter}
        handlePartnerFilterChange={handlePartnerFilterChange}
        handleAdd={handleAdd}
      />
      <OffersControls
        offersActive={offersActive}
        offersComplete={offersComplete}
        filteredList={filteredList}
        currentFilterTab={currentFilterTab}
        handleFilterSelect={handleFilterSelect}
        searchString={searchString}
        handleSearchChange={handleSearchChange}
        handlePagination={handlePagination}
        currentPaginationPosition={currentPaginationPosition}
      />
      <OffersList
        offersData={offersData}
        filteredList={filteredList}
        handlePagination={handlePagination}
        currentPaginationPosition={currentPaginationPosition}
        offersDataIsFetching={offersDataIsFetching}
        handleConfirm={handleConfirm}
        handleEdit={handleEdit}
        handleChangeOfferStatus={handleChangeOfferStatus}
      />
      <style>{`
      .profile-offer-modal {
          font-size: 16px;

          & button {
          font-size: 16px;

          }
        }
      `}</style>

      <OfferModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
        partnersList={partnersSelectList}
        className="profile-offer-modal"
      />
      <ConfirmModal
        text={t("PartnerDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(Offers);
