import PaginationArrows from "@/components/PaginationArrows";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { EFilterTabs, FILTER_TABS } from "@/shared/consts/offers";
import { Input } from "@/shared/ui/Form";
import { IOffer } from "@op/entities";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

interface IOffersControlsProps {
  offersActive: IOffer[] | undefined;
  offersComplete: IOffer[] | undefined;
  filteredList: IOffer[];
  currentFilterTab: EFilterTabs;
  handleFilterSelect: (value: number) => void;
  searchString: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePagination: (next?: boolean) => void;
  currentPaginationPosition: number;
}

function OffersControls({
  offersActive,
  offersComplete,
  filteredList,
  currentFilterTab,
  handleFilterSelect,
  searchString,
  handleSearchChange,
  handlePagination,
  currentPaginationPosition,
}: IOffersControlsProps) {
  const { t } = useTranslation();

  return (
    <div className="profile__control">
      <div className="profile__control-item">
        <ul className="profile__tabs">
          {FILTER_TABS.map((tab) => (
            <li
              className={classNames(
                "profile__tab",
                currentFilterTab === tab.value && "is-active"
              )}
              key={tab.label}
              onClick={() => handleFilterSelect(tab.value)}
            >
              <div className="profile__tab-holder">
                <strong className="profile__tab-name">{t(tab.label)}</strong>
                <span className="profile__tab-number">
                  {tab.value === EFilterTabs.active
                    ? offersActive?.length ?? 0
                    : offersComplete?.length ?? 0}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="profile__control-item">
        <div className="search-input profile__search">
          <Input
            placeholder={t("OffersSearch")}
            className="input_stroke profile__search-input"
            value={searchString}
            onChange={handleSearchChange}
          />
        </div>

        <div className="profile__control-block">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={filteredList?.length || 0}
          />
        </div>
      </div>
    </div>
  );
}

export default OffersControls;
