import { rtkApi } from '@op/api';
import { COOKIE_SESSION_KEY, getCookie } from '@op/core';

import { API_PROMO_URL } from '../../Env';
import { IMktuSearchRequest, IMktuSearchResponse } from '../model/types/mktuSearch';

const mktuSearchOptions = (params: IMktuSearchRequest) => ({
  url: `${API_PROMO_URL}/mktuClass`,
  params: {
    ...params,
    session_key: getCookie(COOKIE_SESSION_KEY),
  },
});

const mktuApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getMktuSearch: build.query<IMktuSearchResponse, IMktuSearchRequest>({
      query: mktuSearchOptions,
    }),
  }),
});

export const useGetMktuSearch = mktuApi.useGetMktuSearchQuery;
export const useLazyGetMktuSearch = mktuApi.useLazyGetMktuSearchQuery;
