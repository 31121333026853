import UserStore from "@/app/providers/MobxStore/model/UserStore";
import { ConfirmModal } from "@/features/Modal";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { useModalConfirm } from "@/shared/lib/hooks/useModalComfirm";
import { useModalOpen } from "@/shared/lib/hooks/useModalOpen";
import { pagination } from "@/shared/lib/utils/pagination";
import { Input } from "@/shared/ui/Form";
import { JournalTagsModal } from "@/widgets/JournalTagsModal";
import {
  IJournalTag,
  useDeleteJournalTagMutation,
  useGetJournalTagList,
} from "@op/entities";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import ItemCard from "../ItemCard";
import PaginationArrows from "../PaginationArrows";

function JournalTags() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] = useState<IJournalTag>();
  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const { data: tags, isLoading: isFetching, refetch } = useGetJournalTagList();

  const [deleteTag] = useDeleteJournalTagMutation();

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        tags?.length ?? 0,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    if (modalConfirm.id) {
      const response = await deleteTag({
        tag_id: modalConfirm.id,
        token: UserStore.token!,
      });
      if (response) {
        refetch();
        setModalConfirm({ isOpen: false, id: null });
      }
    }
  };

  const handleEdit = (tagslug: IJournalTag["tagslug"]) => {
    const itemToEdit = tags?.find((tag) => tag.tagslug === tagslug);
    if (itemToEdit) {
      setModalEditorOpened(true);
      setModalEditorData(itemToEdit);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const filteredList = tags?.filter((tag) =>
    tag.tag.toLowerCase().includes(searchString)
  );

  const handleAdd = () => {
    setModalEditorOpened(true);
    setModalEditorData(undefined);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await refetch();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("TagsList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("TagAdd")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchByTags")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={tags?.length ?? 0}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-rubrics">
        {isFetching && <div>{t("Loading")}...</div>}

        {filteredList
          ?.slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((tag) => (
            <ItemCard
              key={tag.tag_id}
              className="card-rubric"
              cardId={tag.tag_id}
              cardLink={tag.tag}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(tag.tagslug)}
              cells={[
                <React.Fragment key={1}>{tag.tag}</React.Fragment>,
                <React.Fragment key={1}>
                  {tag.is_enabled ? t("TagShow") : t("TagHidden")}
                </React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={filteredList?.length ?? 0}
          />
        </div>
      </div>

      <JournalTagsModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("TagDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(JournalTags);
