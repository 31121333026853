import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_JOURNAL_URL } from '../../Env';
import {
  IAuthor,
  IAuthorAddRequest,
  IAuthorDeleteRequest,
  IAuthorEditRequest,
  IAuthorGetRequest,
  IAuthorStatistics,
  IAuthorStatisticsGetRequest,
  TAuthorListGetResponse,
} from '../model/types/author';

const requestListOptions = () => ({
  url: `${API_JOURNAL_URL}/authors/view`,
  method: 'post',
});

const requestStatisticsOptions = (params: IAuthorGetRequest) => ({
  url: `${API_JOURNAL_URL}/authors/stat/${params.author_id}`,
  method: 'post',
});

const mutationRequestOptions = (
  { token, ...params }: IAuthorAddRequest | IAuthorEditRequest | IAuthorDeleteRequest,
  method: 'add' | 'edit' | 'delete',
) => ({
  url: `${API_JOURNAL_URL}/authors/${method}`,
  body: params,
  method: 'post',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getAuthorList: build.query<IAuthor[], void>({
      query: requestListOptions,
      transformResponse: (response: TAuthorListGetResponse) => response.payload,
    }),
    getAuthorStatistics: build.query<IAuthorStatistics, IAuthorStatisticsGetRequest>({
      query: requestStatisticsOptions,
    }),
    addAuthor: build.mutation<number, IAuthorAddRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'add'),
      }),
    }),
    editAuthor: build.mutation<number, IAuthorEditRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'edit'),
      }),
    }),
    deleteAuthor: build.mutation<number, IAuthorDeleteRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'delete'),
      }),
    }),
  }),
});

export const {
  useGetAuthorListQuery: useGetAuthorList,
  useLazyGetAuthorListQuery: useLazyGetAuthorList,
  useAddAuthorMutation,
  useEditAuthorMutation,
  useDeleteAuthorMutation,
} = api;

export const getAuthorList = (): Promise<AxiosResponse<IAuthor[]>> =>
  $api.request({
    ...requestListOptions(),
    transformResponse: [
      (data) => {
        const parsedData = JSON.parse(data);
        return parsedData.payload;
      },
    ],
  });

export const getAuthorStatistics = (params: IAuthorStatisticsGetRequest): Promise<AxiosResponse<IAuthorStatistics>> =>
  $api.request(requestStatisticsOptions(params));
