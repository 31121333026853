import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_JOURNAL_URL } from '../../Env';
import {
  IJournalTag,
  IJournalTagAddRequest,
  IJournalTagDeleteRequest,
  IJournalTagEditRequest,
  IJournalTagListGetResponse,
} from '../model/types/journalTag';

const requestListOptions = () => ({
  url: `${API_JOURNAL_URL}/tags/view`,
  method: 'post',
});

const mutationRequestOptions = (
  { token, ...params }: IJournalTagAddRequest | IJournalTagEditRequest | IJournalTagDeleteRequest,
  method: 'add' | 'edit' | 'delete',
) => ({
  url: `${API_JOURNAL_URL}/tags/${method}`,
  body: params,
  method: 'post',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getJournalTagList: build.query<IJournalTag[], void>({
      query: requestListOptions,
      transformResponse: (response: IJournalTagListGetResponse) => response.payload,
    }),
    addJournalTag: build.mutation<number, IJournalTagAddRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'add'),
      }),
    }),
    editJournalTag: build.mutation<number, IJournalTagEditRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'edit'),
      }),
    }),
    deleteJournalTag: build.mutation<number, IJournalTagDeleteRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'delete'),
      }),
    }),
  }),
});

export const {
  useGetJournalTagListQuery: useGetJournalTagList,
  useLazyGetJournalTagListQuery: useLazyGetJournalTagList,
  useAddJournalTagMutation,
  useEditJournalTagMutation,
  useDeleteJournalTagMutation,
} = api;

export const getJournalTags = (): Promise<AxiosResponse<IJournalTag[]>> =>
  $api.request({
    ...requestListOptions(),
    transformResponse: [
      (data) => {
        const parsedData = JSON.parse(data);
        return parsedData.payload;
      },
    ],
  });
