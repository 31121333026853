import { MultiSelect } from "@/shared/ui/Form";
import classNames from "classnames";
import { t } from "i18next";
import { Control, Controller, FieldErrors } from "react-hook-form";

interface ItemWithIdAndTitle {
  id: number;
  title: string;
}

interface IProps<T extends ItemWithIdAndTitle> {
  items: T[] | undefined;
  name: string;
  control: Control<any>;
  errors: FieldErrors;
  maxSelectedItems?: number;
}

export const SelectSimilarController = <T extends ItemWithIdAndTitle>({
  name,
  errors,
  control,
  items,
  maxSelectedItems = 5,
}: IProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        const selectedItems = items?.filter((item) => value?.includes(item.id));

        return (
          <MultiSelect
            className="editor-header__select-similar"
            dropDownClassName="top-start"
            value={value}
            setValue={(newValue, e?: React.SyntheticEvent) => {
              e?.preventDefault();
              onChange(newValue);
            }}
            maxSelectedItems={maxSelectedItems}
            searchFunction={({ label, search }) =>
              label.toLowerCase().includes(search.toLowerCase())
            }
            searchPlaceholder={t("SearchSimilarArticles")}
            items={items?.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
            button={() => (
              <div
                className={classNames([
                  "btn btn-outline black scale-up lighten-up size-s",
                  !!errors[name] && "red",
                ])}
              >
                <i className="btn__text select__btn-text">
                  {selectedItems && selectedItems?.length > 0
                    ? selectedItems?.map((item) => (
                        <div key={item.id}>{item.title}</div>
                      ))
                    : t("ChooseSimilarArticles")}
                </i>
                <span className="icon icon-cursor-down select__btn-icon" />
              </div>
            )}
            option={(item: string) => (
              <>
                <em className="dropdown__text select__text">{item}</em>
              </>
            )}
            isInvalid={!!errors[name]}
          />
        );
      }}
    />
  );
};
