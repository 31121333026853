import { ECategory } from "@op/entities";
import { formatCategoryLabel } from "@/shared/lib/utils/formatCategoryLabel";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

function Categories() {
  const { t } = useTranslation();

  const list = Object.values(ECategory).map((item: ECategory) => ({
    id: item,
    name: item,
  }));

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("CategoriesList")}</strong>
      </div>
      <div className="profile__body profile-rubrics">
        {list.map((item) => (
          <article key={item.id} className="card-category item-card">
            <div className="item-card__cell">
              <p>
                {t(formatCategoryLabel(item.name))}
              </p>
            </div>
          </article>
        ))}
      </div>
    </>
  );
}

export default observer(Categories);
