import { SetStateAction, useState } from "react";

interface IUseModalConfirm {
  isOpen: boolean;
  id: number | null;
}

export function useModalConfirm(): [
  IUseModalConfirm,
  React.Dispatch<SetStateAction<IUseModalConfirm>>
] {
  const [modalConfirm, setModalConfirm] = useState<IUseModalConfirm>({
    isOpen: false,
    id: null,
  });
  return [modalConfirm, setModalConfirm];
}
