import { getFaqArticlesModalEditorOpen, uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { FaqArticlesModalEditor } from "@/widgets/FaqArticlesModalEditor";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const FaqArticlesCreator = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useSelector(getFaqArticlesModalEditorOpen);

  const handleClose = useCallback(() => {
    dispatch(uiActions.setFaqArticlesModalEditorOpen(false));
  }, [dispatch]);

  const handleSave = useCallback(() => {
    navigate(ROUTES.FAQ.ARTICLES.path);
    handleClose();
  }, [navigate, handleClose]);

  return (
    <FaqArticlesModalEditor
      open={open}
      onClose={handleClose}
      onSave={handleSave}
    />
  );
};
