import { getPartnerCreatorOpen, uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { PartnerModal } from "@/widgets/PartnerModal";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const PartnerCreator = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const open = useSelector(getPartnerCreatorOpen);

  const handleClose = useCallback(() => {
    dispatch(uiActions.setPartnersCreatorOpen(false));
  }, [dispatch]);

  const handleSave = useCallback(() => {
    navigate(ROUTES.PARTNERS.PARTNERS.path);
    handleClose();
  }, [navigate, handleClose]);

  return (
    <PartnerModal
      open={open}
      onClose={handleClose}
      data={null}
      onSave={handleSave}
    />
  );
};
